export default class MODULE_API {
  constructor(url) {
    this.url = url;
  }

  async getUsersByIds(token, users) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Barer ${token}`,
    };
    const url = process.env.REACT_APP_URL + "/users/data";
    const data = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ users }),
    });
    const refined = await data.json();
    return JSON.parse(refined);
  }

  async potPartners(token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Barer ${token}`,
    };
    const url = process.env.REACT_APP_URL + "/user/potPartners";
    const data = await fetch(url, {
      method: "GET",
      headers,
    });
    const refined = await data.json();
    return JSON.parse(refined);
  }

  async userData(token, userID) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Barer ${token}`,
    };
    const url = process.env.REACT_APP_URL + "/user/data";
    const data = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ userID: userID }),
    });
    const refined = await data.json();
    return JSON.parse(refined);
  }

  async login(login) {
    const headers = { "Content-Type": "application/json" };
    const url = process.env.REACT_APP_URL + "/user/login";
    const data = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(login),
    });
    const refined = await data.json();
    return JSON.parse(refined);
  }

  async register(userData) {
    const headers = { "Content-Type": "application/json" };
    const url = process.env.REACT_APP_URL + "/user/register";
    const data = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(userData),
    });
    const refined = await data.json();
    return JSON.parse(refined);
  }

  async posts(token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Barer ${token}`,
    };
    const url = process.env.REACT_APP_URL + "/posts/get";
    const data = await fetch(url, {
      method: "GET",
      headers,
    });

    const refined = await data.json();
    return refined;
  }

  async getLatestPost() {
    const headers = {
      "Content-Type": "application/json",
    };
    const url = process.env.REACT_APP_URL + "/post/latestPost";
    const data = await fetch(url, {
      method: "GET",
      headers,
    });

    const refined = await data.json();
    return refined;
  }

  async setInitialData(userProps) {
    const headers = { Authorization: `Barer ${localStorage.getItem("token")}` };
    const url = process.env.REACT_APP_URL + "/user/setInitialData";
    const data = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(userProps),
    });

    const refined = await data.json();

    return refined;
  }

  async userPreview(token) {
    const headers = { Authorization: `Barer ${token}` };
    const url = process.env.REACT_APP_URL + "/users/preview";
    const data = await fetch(url, {
      method: "GET",
      headers,
    });

    const refined = await data.json();
    return refined;
  }

  async registrationData() {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Barer ${token}` };
    const url = process.env.REACT_APP_URL + "/data/registrationData";
    const data = await fetch(url, {
      method: "GET",
      headers,
    });

    const refined = await data.json();
    return refined;
  }

  async test(token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Barer ${token}`,
    };
    const url = process.env.REACT_APP_URL + "/test/string";
    const data = await fetch(url, {
      method: "GET",
      headers,
    });

    const refined = await data.json();
    return refined;
  }

  async faq(token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Barer ${token}`,
    };
    const url = process.env.REACT_APP_URL + "/data/faq";
    const data = await fetch(url, {
      method: "GET",
      headers,
    });

    const refined = await data.json();
    return refined;
  }

  async getPost(token, input) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Barer ${token}`,
    };
    const url = process.env.REACT_APP_URL + "/post/get";
    const data = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(input),
    });

    const refined = await data.json();
    return refined;
  }

  async getPictures(token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Barer ${token}`,
    };
    const url = process.env.REACT_APP_URL + "/user/pictures";
    const data = await fetch(url, {
      method: "GET",
      headers,
    });

    const refined = await data.json();
    return refined;
  }

  async deletePicture(token, pictureData) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Barer ${token}`,
    };
    const url = process.env.REACT_APP_URL + "/user/deletePicture";
    const data = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(pictureData),
    });

    const refined = await data.json();
    return refined;
  }

  async uploadProfilePicture(file, token) {
    const headers = { Authorization: `Barer ${token}` };
    const url = process.env.REACT_APP_URL + "/user/image";
    const data = await fetch(url, {
      method: "POST",
      headers,
      body: file,
    });

    const refined = await data.json();
    return refined;
  }

  async updateProfileData(token, newData) {
    const headers = { Authorization: `Barer ${token}` };
    const url = process.env.REACT_APP_URL + "/user/update";
    const data = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(newData),
    });

    console.log(data);
    const refined = await data.json();
    return refined;
  }

  async search(token, query) {
    const headers = { Authorization: `Barer ${token}` };
    const url = process.env.REACT_APP_URL + "/user/search";
    const data = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(query),
    });

    const refined = await data.json();
    return refined;
  }

  async getSocialmediaLinks() {
    const headers = {
      "Content-Type": "application/json",
    };
    const url = process.env.REACT_APP_URL + "/data/social";
    const data = await fetch(url, {
      method: "GET",
      headers,
    });

    const refined = await data.json();
    return refined;
  }
}
