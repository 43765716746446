import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./pages/Checkout";

import ScrollToTop from "./helpers/ScrollToTop";

//Contains header, footer and more
import Layout from "./layout";

import LoggedOutHeader from "./helpers/LoggedOutHeader";
import Policy from "./pages/Policy";
import Login from "./pages/Login";
import Frontpage from "./pages/Frontpage";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import About from "./pages/about";
import Chat from "./pages/Chat";
import PrivateChat from "./PrivateChat";
import PostPage from "./pages/PostPage";
import Archive from "./pages/archive";
import Reset from "./pages/reset";
import ProfileSetup from "./pages/ProfileSetup";
import ProfilePicturesEdit from "./pages/ProfilePicturesEdit";
import Profile from "./pages/Profile/Profile";
import User from "./pages/User";
import Matches from "./pages/Matches/Matches";
import Search from "./pages/Search/Search";
import PopupContextProvider from "../context/popupContext";
import Subscriptions from "./pages/Subscriptions";

const promise = loadStripe(
  "pk_live_51IQH2gIEqJkaPD5pIjuIoWJl5YCnqXc6XWJaMXVwDS9eDr1UvCTG3VnsMPXRCQb3tA3KxPZk8dwwJIMsrHoXo3qv00mLWzAdUr"
);

function RouterHandler() {
  const [state, change] = useContext(AuthContext);

  //Sign user out after 1 hour
  useEffect(() => {
    setTimeout(() => {
      change.logout();
    }, 3600000);
  }, [state.token, change]);

  return state.token ? (
    <Router>
      <ScrollToTop />
      <PopupContextProvider id={state.userID} token={state.token}>
        {state.regisrationComplete ? (
          <>
            <Route
              path="/"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <Frontpage token={state.token} />
                  </Layout>
                );
              }}
            />

            <Route
              path="/archive"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <Archive token={state.token} />
                  </Layout>
                );
              }}
            />

            <Route
              path="/subscriptions"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <Subscriptions token={state.token} />{" "}
                  </Layout>
                );
              }}
            />

            <Route
              path="/checkout"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <Elements stripe={promise}>
                      <CheckoutForm token={state.token} />
                    </Elements>
                  </Layout>
                );
              }}
            />

            <Route
              path="/chat"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <Chat token={state.token} />{" "}
                  </Layout>
                );
              }}
            />

            <Route
              path="/chat/user/:id"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <PrivateChat
                      room={routeProps.match.params.id}
                      userID={state.userID}
                      token={state.token}
                    />
                  </Layout>
                );
              }}
            />
            <Route
              path="/post/:id"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <PostPage
                      id={routeProps.match.params.id}
                      userID={state.userID}
                      token={state.token}
                    />
                  </Layout>
                );
              }}
            />

            <Route
              path="/user/:id"
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <User
                      token={state.token}
                      id={routeProps.match.params.id}
                      myid={state.userID}
                    />
                  </Layout>
                );
              }}
            />

            <Route
              path="/profile"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <Profile token={state.token} />
                  </Layout>
                );
              }}
            />

            <Route
              path="/contact"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <Contact />
                  </Layout>
                );
              }}
            />

            <Route
              path="/faq"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <Faq token={state.token} />
                  </Layout>
                );
              }}
            />

            <Route
              path="/about-us"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    {" "}
                    <About token={state.token} />
                  </Layout>
                );
              }}
            />

            <Route
              path="/pictures"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <ProfilePicturesEdit token={state.token} />
                  </Layout>
                );
              }}
            />

            <Route
              path="/policy"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <Policy />
                  </Layout>
                );
              }}
            />

            <Route
              path="/matches"
              exact={true}
              render={(routeProps) => {
                return (
                  <Layout change={change.logout}>
                    <Matches token={state.token} />
                  </Layout>
                );
              }}
            />

            <Route
              path="/search"
              exact={true}
              render={(routeProps) => {
                localStorage.firstSearch = 1
                return (
                  <Layout change={change.logout}>
                    <Search token={state.token} />
                  </Layout>
                );
              }}
            />
          </>
        ) : (
          <Route
            path="/"
            exact={true}
            render={(routeProps) => {
              return (
                <>
                  <LoggedOutHeader hidebutton />
                  <ProfileSetup {...routeProps} change={change} />
                </>
              );
            }}
          />
        )}
      </PopupContextProvider>
    </Router>
  ) : (
    <Router>
      <ScrollToTop />
      <PopupContextProvider>
        <Route
          exact={true}
          path="/login"
          render={(routeProps) => {
            return <Login />;
          }}
        />

        <Route
          exact={true}
          path="/register"
          render={(routeProps) => {
            return <Login register />;
          }}
        />

        <Route
          path="/faq"
          exact={true}
          render={(routeProps) => {
            return <Faq loggedout />;
          }}
        />

        <Route
          path="/about-us"
          exact={true}
          render={(routeProps) => {
            return <About loggedout />;
          }}
        />

        <Route
          path="/policy"
          exact={true}
          render={(routeProps) => {
            return <Policy loggedout />;
          }}
        />
        <Route
          path="/contact"
          exact={true}
          render={(routeProps) => {
            return <Contact loggedout />;
          }}
        />

        <Route
          path="/archive"
          exact={true}
          render={(routeProps) => {
            return <Archive token={state.token} loggedout />;
          }}
        />

        <Route
          path="/reset"
          exact={true}
          render={(routeProps) => {
            return <Reset token={state.token} loggedout />;
          }}
        />

        <Route
          path="/"
          exact={true}
          render={(routeProps) => {
            return (
              <>
                <LoggedOutHeader />
                <Frontpage token={state.token} loggedout />
              </>
            );
          }}
        />

        <Route
          path="/post/:id"
          exact={true}
          render={(routeProps) => {
            return (
              <PostPage
                loggedout
                id={routeProps.match.params.id}
                userID={state.userID}
                token={state.token}
              />
            );
          }}
        />
      </PopupContextProvider>
    </Router>
  );
}

export default RouterHandler;
