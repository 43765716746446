import React, { useEffect, useState, useContext } from "react";
import Loading from "../../helpers/Loading";
import MODULE_API from "../../../API/api";
import UserDisplay from "./UserDisplay";
import { PopupContext } from "../../../context/popupContext";

const API = new MODULE_API();

export default function Matches(props) {
  const [popup, changePopup] = useContext(PopupContext);
  const [active, setActive] = useState(0);
  const [premium, setPremium] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    likes: [],
    visitors: [],
    matches: [],
  });

  useEffect(() => {
    async function getData() {
      const raw_data = await API.potPartners(props.token);

      //Show upgrade popup
      if (!raw_data.data.premium) {
        changePopup.showPopup(true, "subscribe");
      } else {
        setPremium(true);
      }

      setData({
        likes: raw_data.data.likes,
        visitors: raw_data.data.visitors,
        matches: raw_data.data.matches,
      });

      setLoading(false);
    }

    getData();
  }, [props.token]);

  function setActiveBtn(e) {
    const buttons = Array.from(e.target.parentElement.children);
    setActive(buttons.indexOf(e.target));
    buttons.forEach((element) => {
      element === e.target
        ? (element.classList = "active")
        : (element.classList = "");
    });
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="matches-page">
        <nav className="nav">
          <button
            className="active"
            onClick={(e) => {
              setActiveBtn(e);
            }}
          >
            Mina likes<b>({data.likes.length})</b>
          </button>
          <button
            onClick={(e) => {
              setActiveBtn(e);
            }}
          >
            Mina Besökare <b>({data.visitors.length})</b>
          </button>
          <button
            onClick={(e) => {
              setActiveBtn(e);
            }}
          >
            Matchningar <b>({data.matches.length})</b>
          </button>
        </nav>
      </div>

      <UserDisplay
        token={props.token}
        view={active}
        premium={premium}
        likes={data.likes}
        visitors={data.visitors}
        matches={data.matches}
      />
    </>
  );
}
