import React from "react";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { PopupContext } from "../context/popupContext";
import Logo from "./helpers/Logo";
import englishf from "../assets/images/english.svg";
import swedishf from "../assets/images/swedish.svg";
import prenumerationer from "../assets/images/prenumerationer.svg";
import faq from "../assets/images/faq-bubble.svg";
import omoss from "../assets/images/om-oss.svg";
import kontakt from "../assets/images/kontakt.svg";
import installningar from "../assets/images/installningar.svg";
import logout from "../assets/images/logout.svg";
import loupe from "../assets/images/loupe.svg";
export default function Header(props) {
  const [navOpen, setNavOpen] = useState(false);
  const [english, setEnglish] = useState(false);
  const [popup, changePopup] = useContext(PopupContext);
  return (
    <header className="header hide-large">
      <Link to="/">
        <Logo />
      </Link>
      <button
        id="btn-lang"
        onClick={(e) => {
          changePopup.showPopup(true, "lang");
        }}
      >
        <img src={!english ? englishf : swedishf} alt="english flag" />
      </button>

      <button
        className="hamburger"
        onClick={(e) => {
          Array.from(
            document.querySelectorAll(".hamburger")
          )[0].classList.toggle("change");
          setNavOpen(!navOpen);
        }}
      >
        <div className="hamburger">
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </button>
      <nav className={navOpen ? "sidebar open" : "sidebar"}>
        <Link to="/subscriptions" className="nav-link">
          <img className="nav-link-img" src={prenumerationer} alt="subscriptions"/>
        <span className="nav-link-title">  Mina prenumerationer</span>

        </Link>
        <Link to="/about-us" className="nav-link">
          <img className="nav-link-img" src={omoss} alt="Om oss"/>
        <span className="nav-link-title">  Om oss</span>

        </Link>
        <Link to="/contact" className="nav-link">
          <img className="nav-link-img" src={kontakt} alt="kontakt"/>
        <span className="nav-link-title">  Kontakt</span>

        </Link>
        <Link to="/faq" className="nav-link">
          <img className="nav-link-img" src={faq} alt="Faq"/>
        <span className="nav-link-title">  Vanliga frågor och svar</span>

        </Link>
        <Link to="/profile" className="nav-link">
          <img className="nav-link-img" src={installningar} alt="installningar"/>
        <span className="nav-link-title">  Inställningar</span>

        </Link>

        {!props.loggedout ? (
          <button
            onClick={() => {
              props.logout();
            }}
            className="logout"
          >
            <img className="nav-link-img" src={logout} alt="Logout"/>
            <span>Logga ut</span>
          </button>
        ) : (
          ""
        )}
      </nav>
    </header>
  );
}
