import React, { useState } from "react";
import MODULE_API from "../../../API/api";
import loadingImg from "../../../assets/images/loading.gif";
import add from "../../../assets/images/add.svg";

const API = new MODULE_API();

export default function UploadPicture(props) {
  const [loading, setLoading] = useState(false);
  //Upload file
  async function onFileSelect(file) {
    setLoading(true);
    const formData = new FormData();
    const fileName = Math.random() * 1000 + file.name;

    formData.append(fileName, file);

    if (file.size > 750000) {
      alert("Filen är för stor, välj en bild som är max 0.5 MB");
      setLoading(false);
    } else {
      const raw_res = await API.uploadProfilePicture(formData, props.token);
      const res = JSON.parse(raw_res);

      setLoading(false);
      props.setPictures((prevState) => {
        return [
          ...prevState,
          {
            picture: {
              id: res.data.id,
              url: res.data.url,
            },
          },
        ];
      });
    }
  }
  return (
    <div className="picture add">
      <input
        type="file"
        name="myFile"
        style={{ display: "none" }}
        accept="image/*"
        onChange={(e) => {
          onFileSelect(e.target.files[0]);
        }}
      />
      <img
        alt="add"
        onClick={(e) => {
          if (!loading) {
            e.target.parentElement.children[0].click();
          }
        }}
        src={loading ? loadingImg : add}
      />
    </div>
  );
}
