import React, { useState, useEffect } from "react";
import axios from "axios";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import checkmark from "../../assets/images/check.svg";

import visa from "../../assets/images/visa.svg";
import mastercard from "../../assets/images/mastercard.svg";
import american_express from "../../assets/images/american_express.svg";

import ProductCard from "../helpers/productCard";
export default function Checkout(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState("");

  const [products, setProducts] = useState(false);
  const [activeProd, setactiveProd] = useState(0);

  useEffect(() => {
    async function getProducts() {
      axios.get(process.env.REACT_APP_URL + "/data/products").then((data) => {
        const products = JSON.parse(data.data);
        setProducts(products);
      });
    }
    getProducts();

    //Check URL to get active
    if (window.location.href.includes("#")) {
      const split = window.location.href.split("#");
      if (split[1] === "1") {
        setactiveProd(1);
      }
    }
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        height: "20px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmitSub = async (event) => {
    setProcessing(true);
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });

    if (result.error) {
      console.log(result.error);
      console.log(result.error.message);
      if ((result.code = "email_invalid")) {
        setError("E-postadressen är inte gilltig eller felskriven");
      }
      setProcessing(false);
    } else {
      setError("");
      const config = { headers: { Authorization: "Barer " + props.token } };
      const res = await axios.post(
        process.env.REACT_APP_URL + "/subscription/paymentIntent",
        {
          payment_method: result.paymentMethod.id,
          email: email,
          priceID: products[activeProd].id,
        },
        config
      );
      // eslint-disable-next-line camelcase
      const { client_secret, status } = res.data;

      if (status === "requires_action") {
        stripe.confirmCardPayment(client_secret).then(function (result) {
          if (result.error) {
            console.log(result.error);
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
          } else {
            // Show a success message to your customer
            setSucceeded(true);
            setProcessing(false);
          }
        });
      } else {
        setSucceeded(true);
        setProcessing(false);
        // No additional information was needed
        // Show a success message to your customer
      }
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmitSub}>
      <h1>Nelale Premium</h1>
      <div className="products">
        {products
          ? products.map((prod) => {
              return (
                <ProductCard
                  active={products.indexOf(prod) === activeProd}
                  interval={prod.interval}
                  price={prod.price}
                  click={() => {
                    setactiveProd(products.indexOf(prod));
                  }}
                />
              );
            })
          : ""}
      </div>

      <div className="flex align-center my-2">
        <img src={checkmark} alt="checkmark" />
        <p className="ml-1 ">Ingen bindnigstid!</p>
      </div>
      <div className="flex align-center my-2">
        <img src={checkmark} alt="checkmark" />
        <p className="ml-1 ">Se dina likes, besökare och matchingar</p>
      </div>
      <div className="flex align-center my-2">
        <img src={checkmark} alt="checkmark" />
        <p className="ml-1 ">Bli invjuden till våra VIP-event</p>
      </div>

      <label className="preinput text-gray">E-post</label>
      <input
        placeholder="E-post"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <label className="preinput text-gray">Kortnummer</label>
      <CardElement
        id="card-element"
        options={cardStyle}
        onChange={handleChange}
      />
      <h3 className="payment-price">
        <label className="text-gray">Att betala: </label>
        {products
          ? products[activeProd].price / products[activeProd].interval
          : ""}
        kr
      </h3>
      <button disabled={processing || disabled || succeeded} id="submit">
        <span id="button-text">
          {processing ? <div className="spinner" id="spinner"></div> : "Betala"}
        </span>
      </button>

      <img src={visa} alt="visa" />
      <img src={mastercard} alt="mastercard" />
      <img src={american_express} alt="american express" />

      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Betalningen gick igenom! Du kan se din prenumeration under&nbsp;
        <Link to="/subscriptions">mina prenumerationer</Link>
      </p>
      <p className="text-gray text-center my-2 mb-4">
        Genom att slutföra köpet av prenumerationen accepterar jag
        betalningsansvar i englighet med{" "}
        <Link s to="/policy">
          Nelales allmänna villkor
        </Link>
        .
      </p>
    </form>
  );
}
