import logo from "../../assets/logo/logo.svg";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { PopupContext } from "../../context/popupContext";
import englishf from "../../assets/images/english.svg";

export default function LoggedOutHeader(props) {
  const [popup, changePopup] = useContext(PopupContext);
  return (
    <div className="login-header">
      <Link to="/">
        <img src={logo} alt="Nelale logo" />
      </Link>

      <button
        id="btn-lang"
        onClick={(e) => {
          changePopup.showPopup(true, "lang");
          // setEnglish(!english);
          // const google_el = document.querySelectorAll(".goog-te-menu-frame")[0]
          //   .contentWindow.document;

          // const stack = Array.from(google_el.getElementsByTagName("span"));
          // const needle = english ? "Swedish" : "English";
          // stack.forEach((element) => {
          //   if (element.innerHTML === needle) {
          //     element.parentElement.parentElement.click();
          //   }
          // });
        }}
      >
        <img src={englishf} alt="english flag" />
      </button>
      <div>
        <Link className="btn hide-small" to="/login">
          Logga in
        </Link>
        <Link className="btn hide-small" to="/register">
          Skapa konto
        </Link>
        {props.hidebutton ? (
          ""
        ) : (
          <Link className="btn hide-large" to="/login">
            Hitta singlar
          </Link>
        )}
      </div>
    </div>
  );
}
