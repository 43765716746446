import React from "react";
import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import wave from "../../assets/images/wave.svg";
import Loading from "../helpers/Loading";

import arrow from "../../assets/images/arrow.svg";

import chat from "../../assets/images/chat-bg.svg";
import like from "../../assets/images/like.svg";
import remove from "../../assets/images/remove.svg";
import nav from "../../assets/images/nav.svg";
import nav2 from "../../assets/images/nav2.svg";
import missingImage from "../../assets/images/no-image.png";

import MODULE_API from "../../API/api";

import axios from "axios";
import { PopupContext } from "../../context/popupContext";
import { useHistory } from "react-router-dom";

const API = new MODULE_API();

export default function User(props) {
  const [popup, changePopup] = useContext(PopupContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [imageIndex, setImageIndex] = useState(0);
  const [imageAmount, setImageAmount] = useState(0);
  const history = useHistory();

  const [commonChat, setCommonChat] = useState(false);

  useEffect(() => {
    async function userData() {
      const res = await API.userData(props.token, parseInt(props.id));
      setData(res.data);
      setLoading(!loading);
    }

    axios
      .post(
        process.env.REACT_APP_URL + "/chat/check",
        { id: props.id },
        {
          headers: {
            Authorization: "Barer: " + localStorage.token,
          },
        }
      )
      .then((res) => {
        const room = res.data;
        setCommonChat(room);
      })
      .catch((err) => {
        console.log(err);
      });

    setInterval(() => {
      const imageCont = document.getElementById("imgcont");

      if (imageCont) {
        setImageAmount(Array.from(imageCont.children).length - 1);

        const scrollLeft = imageCont.scrollLeft;
        const childLength =
          imageCont.scrollWidth / Array.from(imageCont.children).length;

        setImageIndex(Math.round(scrollLeft) / Math.round(childLength));
      }
    }, 250);

    userData();
  }, [props.token, props.id]);

  async function likeUser(e) {
    const url = process.env.REACT_APP_URL + "/user/like";
    const headers = { Authorization: "Barer " + props.token };
    axios
      .post(url, { id: props.id }, { headers: headers })
      .then(function (response) {
        const data = JSON.parse(response.data);
        //The user you liked has also liked you, show match popup!

        if (data.data.match) {
          const pic1 = data.data.data.profile1.picture.url;
          const pic2 = data.data.data.profile2.picture.url;

          changePopup.showPopup(true, "match", {
            room: data.data.data.room,
            pic1: pic1,
            pic2: pic2,
          });
          //Show match popup
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function unmatch() {
    //props.id = other user id
    //props.myid = (me) or in token
    const url = process.env.REACT_APP_URL + "/user/unmatch";
    const headers = { Authorization: "Barer " + props.token };
    axios
      .post(url, { id: props.id }, { headers: headers })
      .then(function (response) {
        const data = JSON.parse(response.data);
        //The user you liked has also liked you, show match popup!
        if (data) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function scrollToImage(index) {
    const imageCont = document.getElementById("imgcont");
    const children = Array.from(imageCont.children);
    children[index].scrollIntoView({
      behavior: "smooth",
    });
  }

  return loading ? (
    <Loading />
  ) : (
    <div className="user-profile">
      <Link
        onClick={() => {
          history.goBack();
        }}
        className="back"
      >
        <img src={arrow} />
        <p> Gå tillbaka</p>
      </Link>
      <div>
        <section className="pictures">
          <div id="imgcont" alt="profilbild" className="pictures-cont">
            {data.pictures ? (
              data.pictures.map((p) => {
                const pic = p.picture;
                return <img key={pic.url} alt="profile" src={pic.url} />;
              })
            ) : (
              <img src={missingImage} alt="missing" />
            )}
          </div>
          <img
            alt="tillbaka"
            src={nav2}
            className={imageIndex === 0 ? "hide-small hide" : "hide-small"}
            id="pick-back"
            onClick={() => {
              setImageIndex(imageIndex - 1);
              scrollToImage(imageIndex - 1);
            }}
          />
          <img
            alt="fram"
            src={nav}
            className={
              imageIndex === imageAmount || imageAmount === -1
                ? "hide-small hide"
                : "hide-small"
            }
            id="pick-forward"
            onClick={() => {
              setImageIndex(imageIndex + 1);
              scrollToImage(imageIndex + 1);
            }}
          />

          <img alt="wave" className="wave" src={wave} />
          <nav>
            {data.pictures
              ? data.pictures.map((p) => {
                  const index = data.pictures.indexOf(p);
                  return (
                    <label
                      className={
                        imageIndex === index ? "bullet selected" : "bullet"
                      }
                      key={index}
                    ></label>
                  );
                })
              : ""}
          </nav>
        </section>

        <section className="like-chat">
          {props.myid !== props.id ? (
            data.liked ? (
              <img
                className="unmatch"
                src={remove}
                alt="remove"
                onClick={(e) => {
                  if (
                    window.confirm("Are you sure you want to remove match?")
                  ) {
                    unmatch(props.myid);
                  }
                }}
              />
            ) : (
              <img
                src={data.liked ? "Heartfilled.svg" : like}
                className={data.liked ? "liked" : ""}
                onClick={(e) => {
                  if (e.target.className !== "liked") {
                    likeUser(e);
                    e.target.className = "liked";
                    e.target.src = "/Heartfilled.svg";
                  }
                }}
                alt="like"
              />
            )
          ) : (
            ""
          )}
          {data.matched ? (
            <Link to={"/chat/user/" + data.room}>
              <img src={chat} alt="chat" />{" "}
            </Link>
          ) : (
            <button
              style={{
                border: "none",
                background: "transparent",
                marginLeft: "10px",
              }}
              onClick={() => {
                if (commonChat !== false) {
                  window.location.href = "/chat/user/" + commonChat;
                } else {
                  if (data.me_subscriber) {
                    axios
                      .post(
                        process.env.REACT_APP_URL + "/premium/chat",
                        { id: props.id },
                        {
                          headers: {
                            Authorization: "Barer: " + localStorage.token,
                          },
                        }
                      )
                      .then((res) => {
                        const room = res.data;
                        window.location.href = "/chat/user/" + room;
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  } else {
                    changePopup.showPopup(true, "subscribe");
                  }
                }
              }}
            >
              <img src={chat} alt="chat" />{" "}
            </button>
          )}
        </section>

        <div className="padding">
          <h2 className="p-b-s">
            {data.firstname.charAt(0).toUpperCase() +
              data.firstname.substring(1)}
          </h2>
          <p>
            {data.age} år, {data.country} ({data.city})
          </p>
        </div>
      </div>
      <section className="user">
        {data.about_me ? (
          <>
            <h3>Om mig</h3>
            <p className="text-gray">{data.about_me}</p>
          </>
        ) : (
          ""
        )}
        {data.what_want ? (
          <>
            <h3>Jag söker</h3>
            <p className="text-gray">{data.what_want}</p>{" "}
          </>
        ) : (
          ""
        )}
        <h3>Mina intressen</h3>
        <div className="interests">
          {data.hobbies
            ? data.hobbies.map((interest) => {
                return (
                  <label key={interest.hobbie_name} className="card">
                    {interest.hobbie_name}
                  </label>
                );
              })
            : ""}
        </div>
        <h3>Religion</h3>
        <p className="text-gray">
          {data.religion.charAt(0).toUpperCase() +
            data.religion.toLowerCase().slice(1)}
        </p>
      </section>
    </div>
  );
}
