import OptionSelect from "../../helpers/OptionSelect";
import arrow from "../../../assets/images/arrow.svg";
import DropSelect from "../../helpers/DropSelect/DropSelect";
import React, {useEffect, useState} from "react";
import Loading from "../../helpers/Loading";
import "./form.css";
import MODULE_API from "../../../API/api";
import loupe from "../../../assets/images/loupe.svg";

const API = new MODULE_API();

export default function Form(props) {
  const [showForm, setShowForm] = useState(false);

  const [searching, setSearching] = useState(false);

  const [from, setFrom] = useState(18);
  const [to, setTo] = useState("60+");

  let ages = [];
  for (let index = 18; index < 61; index++) {
    if (index < 60) {
      ages.push({ age: index });
    } else {
      ages.push({ age: "60+" });
    }
  }

  const [searchData, setSearchData] = useState({
    gender: "",
    caountry: "",
    age: "18/60+",
    hobbies: [],
  });

  async function Search() {
    console.log(searchData);
    const raw_data = await API.search(props.token, searchData);
    const data = await JSON.parse(raw_data);
    setSearching(false);
    setShowForm(false);

    const myID = parseInt(localStorage.userID);
    const arr = data.filter((item) => {
      if (item.id !== myID) {
        return item;
      }
    });

    props.setResults(arr);
  }
  useEffect(() => Search(),[''])

  function change(e) {
    console.log("e", e);
    let value = "";
    if (e.name !== "clear") {
      value = e.target.value;
      if (value === "ANNAT") {
        value = "";
      }
      setSearchData((prevState) => ({
        ...prevState,
        [e.target.name]: value,
      }));
    } else {
      setSearchData((prevState) => ({
        ...prevState,
        [e.target]: "",
      }));
    }
  }

  return (
    <div className="search-form">
      <label
        onClick={() => {
          setShowForm(!showForm);
        }}
        className="toggle-form"
      >
        <img src={loupe} alt="Search icon" className="search-icon"/>
         Sök och hitta singlar{" "}
        <img
          alt="arrow"
          src={arrow}
          className={showForm ? "arrow" : "arrow open"}
        />
      </label>
      <div className={showForm ? "dropdown" : "dropdown hidden"}>
        <div>
          <h4>Kön</h4>
          <OptionSelect
            selector="gender"
            name="gender"
            data={props.alternatives.genders}
            onChange={(e) => {
              change(e);
            }}
          />
        </div>
        <div>
          <h4>Plats (Land)</h4>
          <select
            name="country"
            onChange={(e) => {
              change(e);
            }}
          >
            <option value="">Alla</option>
            {props.alternatives.country.map((cit) => {
              return <option key={cit.countryname}>{cit.countryname}</option>;
            })}
          </select>
        </div>

        {/* Age */}
        <div className="order">
          <h4>Ålder</h4>
          <div className="two-col">
            <div>
              <p className="text-gray">Från</p>
              <select
                onChange={(e) => {
                  setFrom(e.target.value);
                }}
              >
                {ages.map((age) => {
                  return (
                    <option
                      onChange={(e) => {
                        setFrom(e.target.value);
                      }}
                      value={age.age}
                      key={age.age + "1"}
                    >
                      {age.age}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <p className="text-gray">Till</p>
              <select
                onChange={(e) => {
                  setTo(e.target.value);
                }}
              >
                <option value="60+">60+</option>
                {ages.map((age) => {
                  return (
                    <option value={age.age} key={age.age + "2"}>
                      {age.age}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div>
          <h4>Religion</h4>
          <OptionSelect
            selector="religion_name"
            name="religion_name"
            data={props.alternatives.religions}
            onChange={(e) => {
              e.target.name = "religion";
              change(e);
            }}
          />
        </div>

        <div>
          <h4>Intressen</h4>
          <DropSelect
            selector="hobbie_name"
            title="Välj intressen"
            selected={searchData.hobbies}
            data={props.alternatives.hobbies}
            set={(value) => {
              let v = value;
              if (v === "Annat") {
                setSearchData((prevState) => ({
                  ...prevState,
                  hobbies: [],
                }));
              } else {
                setSearchData((prevState) => ({
                  ...prevState,
                  hobbies: [...prevState.hobbies, { hobbie_name: value }],
                }));
              }
              return true;
            }}
            remove={(value) => {
              const hobs = searchData.hobbies.filter(
                (hob) => hob.hobbie_name !== value
              );
              setSearchData((prevState) => ({
                ...prevState,
                hobbies: hobs,
              }));
              return false;
            }}
          />
        </div>

        <button
          className="button"
          onClick={async () => {
            localStorage.firstSearch = 0
            searchData.age = from + "/" + to;
            if (!props.loggedout) {
              navigator.geolocation.getCurrentPosition(function (position) {
                props.setCords({
                  long: position.coords.longitude,
                  lat: position.coords.latitude,
                });
              });
            }

            setSearching(true);

            Search();
          }}
        >
          {searching ? <Loading /> : "Sök"}
        </button>
      </div>
    </div>
  );
}
