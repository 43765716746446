import { useEffect, useState } from "react";
import { ChatEngine, sendMessage } from "react-chat-engine";
import sendIcon from "../assets/images/send.svg";
import arrow from "../assets/images/arrow.svg";
import { Link } from "react-router-dom";
import noImage from "../assets/images/no-image.png";
import Loading from "./helpers/Loading";
import CalculateDistance from "./helpers/Distance";
import axios from "axios";
import pin from "../assets/images/needle.svg";

export default function PrivateChat(props) {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [preview, setPreview] = useState(false);
  const [message, setMessage] = useState("");
  const [distance, setDistance] = useState(0);
  const [attatch, setAttach] = useState(false);

  function onMessage(data, data2) {
    if (parseInt(props.room) === data) {
      //New message for the room we are in
      console.log(data2);
      //Dont show my own message from this callback, it is shown as soon as i press send.
      if (parseInt(data2.sender.username) !== parseInt(props.userID)) {
        setMessages((prevState) => [...prevState, data2]);
      }
    }
  }

  useEffect(() => {
    async function getChats() {
      var myHeaders = new Headers();
      myHeaders.append("Project-ID", process.env.REACT_APP_CHAT_ID);
      myHeaders.append("User-Name", parseInt(props.userID));
      myHeaders.append("User-Secret", parseInt(props.userID));

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const chatID = parseInt(props.room);

      fetch(
        "https://api.chatengine.io/chats/" + chatID + "/messages",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setMessages(result);

          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }

    async function getUserData() {
      const url = process.env.REACT_APP_URL + "/room/otherUser";
      const headers = { Authorization: "Barer " + props.token };
      axios
        .post(url, { room: props.room }, { headers: headers })
        .then((data) => {
          data = JSON.parse(data.data);

          const { picture, name, age, country, otherUserID, long, lat, city } =
            data;
          setPreview({
            img: picture,
            name: name,
            age: age,
            country: country,
            id: otherUserID,
            city: city,
          });

          if (messages.length !== 0) {
            setMessage("Hej " + preview.name + "!");
          }

          navigator.geolocation.getCurrentPosition(function (position) {
            const d = CalculateDistance(
              parseInt(parseFloat(position.coords.longitude)),
              parseInt(parseFloat(position.coords.latitude)),
              parseInt(parseFloat(long)),
              parseInt(parseFloat(lat))
            );
            setDistance(d);
          });
        });
    }

    getUserData();
    getChats();
  }, [props.userID, props.room, props.token]);

  function sendMessageD(message) {
    Array.from(
      document.querySelectorAll(".ce-message-input-form")[0].children
    )[1].click();
    sendMessage(
      {
        publicKey: process.env.REACT_APP_CHAT_ID,
        userName: parseInt(props.userID),
        userPassword: parseInt(props.userID),
      },
      parseInt(props.room),
      { text: message },
      (data) => {
        if (data) {
          document
            .querySelectorAll(".chat")[0]
            .scroll(0, document.querySelectorAll(".chat")[0].scrollHeight);
        }
      }
    );
  }

  return (
    <section className="chat-container">
      {preview ? (
        <div className="chat-user-topbar">
          <Link to="/chat">
            <img src={arrow} alt="back" className="back" />
          </Link>
          <Link to={"/user/" + preview.id} className="data">
            <img alt="profile pic" src={preview.img ? preview.img : noImage} />
            <div>
              <p>
                <b>
                  {preview.name}, {distance} km
                  <img
                    style={{
                      width: "15px",
                      height: "15px",
                      marginBottom: "-2px",
                    }}
                    src={pin}
                    alt="pin"
                  />
                </b>
              </p>
              <p>
                {preview.age}, {preview.country},{" "}
                {preview.city.charAt(0).toUpperCase() +
                  preview.city.substr(1, preview.city.length)}
              </p>
            </div>
          </Link>
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          position: "fixed",
          left: "-1000vh",
        }}
      >
        <ChatEngine
          publicKey={process.env.REACT_APP_CHAT_ID}
          userName={props.userID}
          userSecret={props.userID}
          onNewMessage={(e, args) => {
            onMessage(e, args);
          }}
        ></ChatEngine>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="chat">
            {messages.length > 0 ? (
              messages.map((msg) => {
                if (msg.attachments.length > 0) {
                  return (
                    <p
                      key={msg.id}
                      className={
                        parseInt(msg.sender.username) === parseInt(props.userID)
                          ? "to noborder"
                          : "from noborder"
                      }
                    >
                      {msg.text !== "" ? <p> {msg.text}</p> : ""}
                      <img src={msg.attachments[0].file} alt="img" />
                    </p>
                  );
                }
                return msg.text !== "" ? (
                  <p
                    key={msg.id}
                    className={
                      parseInt(msg.sender.username) === parseInt(props.userID)
                        ? "to"
                        : "from"
                    }
                  >
                    {msg.text}
                  </p>
                ) : (
                  ""
                );
              })
            ) : (
              <h1 style={{ textAlign: "center" }}>
                Säg Selam till {preview.name} och börja chatta
              </h1>
            )}
            <div className="end"></div>
          </div>

          <div className="chat-input">
            <input
              value={
                messages.length === 0 && message === ""
                  ? "Hej " + preview.name + "!"
                  : message
              }
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              type="text"
              placeholder="Meddelande"
            />

            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="paper-clip"
              width="21px"
              height="21px"
              style={{
                margin: "0 0.5em",
                position: "absolute",
                top: "18px",
                right: "40px",
              }}
              fill="currentColor"
              aria-hidden="true"
              onClick={() => {
                document.getElementById("upload-document-button").click();
                const interval = setInterval(() => {
                  const currentFile = Array.from(
                    document
                      .getElementById("msg-form-container")
                      .querySelectorAll("img")
                  )[0];
                  if (currentFile?.src) {
                    currentFile.parentElement.classList.add("chat-attach");
                    setAttach(true);
                    clear();
                  }
                }, 1000);

                const clear = () => {
                  clearInterval(interval);
                };
              }}
            >
              <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
            </svg>

            <button
              onClick={(e) => {
                console.log(message);
                if (message !== "" || attatch) {
                  sendMessageD(message);

                  //Instantly show my own messages (dont wait for chatengine callback)
                  setMessages((prevState) => [
                    ...prevState,
                    {
                      id: Math.random(0, 100000),
                      sender: { username: props.userID },
                      attachments: [],
                      text: message,
                    },
                  ]);

                  setMessage("");
                }
              }}
            >
              <img src={sendIcon} alt="send" />
            </button>
          </div>
        </>
      )}
    </section>
  );
}
