import React, { useState, useEffect, useContext } from "react";
import MODULE_API from "../../../API/api";
import { AuthContext } from "../../../context/authContext";
import Loading from "../../helpers/Loading";
import axios from "axios";
import OptionSelect from "../../helpers/OptionSelect";
import DropSelect from "../../helpers/DropSelect/DropSelect";
import { createContext } from "react";

const API = new MODULE_API();

export default function TextInfo(props) {
  const [sending, setSending] = useState(false);

  const [saveButtonText, setsaveButtonText] = useState("Spara");

  const [ctx, change] = useContext(AuthContext);
  const [options, setOptions] = useState(false);
  const [newData, setNewData] = useState({
    about_me: "",
    what_want: "",
    country: "",
    religion: "",
    hobbies: [],
    kids: "",
  });

  useEffect(() => {
    async function userData() {
      const raw_data = await API.userData(ctx.token, parseInt(ctx.userID));

      const {
        about_me,
        what_want,
        country,
        religion,
        hobbies,
        city,
        firstname,
        lastname,
        email,
        age,
      } = raw_data.data;

      setNewData({
        about_me: about_me ? about_me : "",
        what_want: what_want ? what_want : "",
        country: country,
        religion: religion,
        hobbies: hobbies,
        firstname: firstname ? firstname : "",
        lastname: lastname ? lastname : "",
        email: email ? email : "",
        age: age ? age : 0,
        city: city ? city : "",
      });
    }

    async function getSetupData() {
      const raw_data = await API.registrationData();
      const parsed = JSON.parse(raw_data);
      const data_fields = JSON.parse(parsed.data);
      setOptions({
        country: data_fields.country,
        hobbies: data_fields.hobbies,
        religions: data_fields.religions,
      });
    }

    getSetupData();
    userData();
  }, [ctx]);

  function onInputChange(e) {
    setNewData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  async function sendData() {
    if (newData.age >= 18) {
      // const raw_data = await API.updateProfileData(ctx.token, newData);
      const data = await axios.post(
        process.env.REACT_APP_URL + "/user/update",
        newData,
        {
          headers: { Authorization: "Barer: " + localStorage.token },
        }
      );
      return data.data;
    } else {
      alert("Du kan som lägst vara 18 år.");
      setSending(false);
    }
  }

  return options ? (
    <section className="profile_about">
      <div>
        <h3>Om mig</h3>
        <textarea
          name="about_me"
          value={newData.about_me}
          onChange={(e) => {
            onInputChange(e);
          }}
          placeholder="Berätta lite om dig själv för att öka chansen att hitta kärleken..."
        />
      </div>
      <div>
        <h3>Vad jag söker</h3>
        <textarea
          name="what_want"
          value={newData.what_want}
          onChange={(e) => {
            onInputChange(e);
          }}
          placeholder="Berätta lite om vem du söker för att öka chansen till matchning..."
        />
      </div>
      <div>
        <h3>Plats (Land)</h3>
        <select
          name="country"
          onChange={(e) => {
            onInputChange(e);
          }}
        >
          <option>{newData.country}</option>
          {options.country.map((cit) => {
            return cit.countryname !== newData.country ? (
              <option key={cit.countryname}>{cit.countryname}</option>
            ) : (
              ""
            );
          })}
        </select>
      </div>
      <div>
        <h3>Religion</h3>
        <OptionSelect
          onChange={(e) => {
            onInputChange(e);
          }}
          name="religion"
          data={options.religions}
          selector="religion_name"
          selected={newData.religion}
        />
      </div>

      <div>
        <h3>Intressen (minst 3 val)</h3>
        <DropSelect
          selector="hobbie_name"
          title="Välj intressen"
          selected={newData.hobbies}
          data={options.hobbies}
          set={(value) => {
            setNewData((prevState) => ({
              ...prevState,
              hobbies: [...prevState.hobbies, { hobbie_name: value }],
            }));
            return true;
          }}
          remove={(value) => {
            const hobs = newData.hobbies.filter(
              (hob) => hob.hobbie_name !== value
            );
            setNewData((prevState) => ({
              ...prevState,
              hobbies: hobs,
            }));
            return false;
          }}
        />
      </div>

      <div>
        <h3>Plats (Stad)</h3>
        <input
          className="profile-input"
          value={newData.city}
          placeholder={newData.city}
          onChange={(e) => {
            setNewData((prevState) => ({
              ...prevState,
              city: e.target.value,
            }));
          }}
        />
      </div>

      <div>
        <h3>Ålder</h3>
        <input
          className="profile-input"
          value={newData.age}
          placeholder={newData.age}
          onChange={(e) => {
            setNewData((prevState) => ({
              ...prevState,
              age: e.target.value,
            }));
          }}
        />
      </div>

      <div>
        <h3>E-post</h3>
        <input
          className="profile-input"
          value={newData.email}
          disabled
          placeholder={newData.email}
        />
      </div>

      <div className="name">
        <h3>Namn</h3>
        <input
          className="profile-input"
          value={newData.firstname}
          placeholder={newData.firstname}
          onChange={(e) => {
            console.log(newData.firstname);
            localStorage.firstname = e.target.value;
            setNewData((prevState) => ({
              ...prevState,
              firstname: e.target.value,
            }));
          }}
        />
        <input
          className="profile-input"
          value={newData.lastname}
          placeholder={newData.lastname}
          onChange={(e) => {
            setNewData((prevState) => ({
              ...prevState,
              lastname: e.target.value,
            }));
          }}
        />
      </div>

      <div className="buttons">
        <button
          className="button"
          onClick={async (e) => {
            setSending(true);
            const res = await sendData();
            if (res === true) {
              setSending(false);
              setsaveButtonText("Sparat!");
            } else {
              console.log("Error when saving data");
            }
          }}
        >
          {sending ? <Loading /> : saveButtonText}
        </button>

        <button
          className="button alternate"
          onClick={() => {
            if (
              window.confirm(
                "Är du säker på att du vill ta bort ditt konto? Detta går ej att ångra."
              )
            ) {
              axios
                .post(process.env.REACT_APP_URL + "/user/delete", "", {
                  headers: { Authorization: "Barer: " + localStorage.token },
                })
                .then(() => {
                  localStorage.removeItem("token");
                  window.location.href = "/";
                })
                .catch((er) => {
                  console.log(er);
                });
            }
          }}
        >
          Ta bort konto
        </button>
      </div>
    </section>
  ) : (
    ""
  );
}
