import React, {useEffect} from "react";
import { AuthContext } from "../../context/authContext";
import { useState, useContext } from "react";
import Logo from "../helpers/Logo";
import BottomSection from "../BottomSection";
import { Link } from "react-router-dom";
import Loading from "../helpers/Loading";
import LoggedOutHeader from "../helpers/LoggedOutHeader";
import logo from "../../assets/logo/logo.svg";
import axios from 'axios'

export default function Login(props) {
  const [auth, change] = useContext(AuthContext);
  const [english, setEnglish] = useState(false);

  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const [login, setLogin] = useState(!props.register);
  const [psw, setPsw] = useState("");

  const [psw2, setPsw2] = useState("");
  const [email, setEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [policy,setPolicy] = useState(false)
  const [showPolicy, setShowPolicy] = useState(false)

  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");



  useState(() => {
    axios
      .get(process.env.REACT_APP_URL + "/data/policy")
      .then((res) => {
        const data = JSON.parse(res.data);
        console.log(data)
        setPolicy(data);
     
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  async function register() {
    if (email) {
      if (psw2 === psw && psw2 !== "" && psw !== "" ) {
        if(!termsAccepted){
          setMessage("Vänligen acceptera villkoren.");
          setIsError(true);
        }else{
          const result = await change.register(email, psw);

          if (result.status === 200) {
            if (result.data === "Invalid email") {
              setMessage("E-postadressen är inte gilltig");
              setIsError(true);
            } else {
              setLogin(true);
              setPsw("");
              setPsw2("");
              setMessage("Registreringen gick igenom! Var god logga in.");
              setIsError(false);
            }
          }

          switch (result.status) {
            case 200:
              break;

            case 409:
              setMessage("E-postadressen är redan registrerad.");
              setIsError(true);
              break;

            default:
              break;
          }
        }

      } else {
        setMessage("Lösenorden saknas eller stämmer inte överense");
        setIsError(true);
      }
    } else {
      setMessage("Vad god ange E-postadress.");
      setIsError(true);
    }
  }

  return (
    <div className="login-container">
      <LoggedOutHeader />
      <div className="branding">
        <h3>Hitta kärleken</h3>
        <Logo white />
      </div>
      <div className="login-field">
        <p className="hide-large">{login ? "Logga in" : "Registrera gratis"}</p>
        <input
          placeholder="E-POST"
          value={email}
          autoComplete="on"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="email"
        />
        <input
          placeholder="LÖSENORD"
          value={psw}
          autoComplete="on"
          onChange={(e) => {
            setPsw(e.target.value);
          }}
          type="password"
        />
        {login ? (
          ""
        ) : (
          <>
            <input
              placeholder="UPPREPA LÖSENORD"
              value={psw2}
              onChange={(e) => {
                setPsw2(e.target.value);
              }}
              type="password"
            />
            <div id="reg-gdpr" style={{"margin-left":"27px"}}>
              <label className="" htmlFor="gdpr">
                <input type="checkbox"
                       checked={termsAccepted}
                       onChange={(e) =>setTermsAccepted(!termsAccepted)}
                       required
                       style={{"width":"auto", "font-size":"small"}}
                       name="termsAccepted" />
            <label onClick={() => {
              setShowPolicy(!showPolicy)
            }}>      Jag har läst och accepterar Nelales användarvillkor <br></br>och är över 18 år gammal.
         </label>
             </label>
            
            </div>
    

            {showPolicy ? <div className='login-policy' dangerouslySetInnerHTML={{__html : policy.post_content}}></div> : ''}
          </>
        )}
        {message ? (
          <div className={isError ? "alert error" : "alert success"}>
            {message}
          </div>
        ) : (
          ""
        )}
        <button
          style={{ marginTop: "3 em" }}
          onClick={async () => {
            if (email === "" || psw === "") {
              setMessage("E-postadress eller lösenord saknas");
              setIsError(true);
            } else {
              setLoading(true);
              setMessage("");
              setIsError(false);
              if (login) {
                const loginres = await change.login(email, psw);

                setLoading(false);
                if (loginres === 404) {
                  setMessage(
                    "Inloggning hittades ej. Var god kontrollera att din E-post samt lösenord stämmer."
                  );
                  setIsError(true);
                } else {
                  window.location.href = "/";
                }
              } else {
                await register();
                setLoading(false);
              }
            }
          }}
        >
          {loading ? <Loading /> : login ? "LOGGA IN" : "REGISTRERA GRATIS"}
        </button>
        <label
          className="text-gray"
          style={{"text-align":"center"}}
          onClick={async (e) => {
            e.preventDefault();
            setLogin(!login);
            setMessage("");
          }}
        >
          {login ? "REGISTRERA GRATIS" : "TILLBAKA TILL LOGGA IN"}
        </label>
        <Link to="/reset" className="invert reset">
          GLÖMT LÖSENORDED?
        </Link>
      </div>
      <BottomSection />
    </div>
  );
}
