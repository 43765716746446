import React, { useState } from "react";
import axios from "axios";
import Loading from "../helpers/Loading";
import LoggedOutHeader from "../helpers/LoggedOutHeader";
import BottomSection from "../BottomSection";

export default function Policy(props) {
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(true);

  useState(() => {
    axios
      .get(process.env.REACT_APP_URL + "/data/policy")
      .then((res) => {
        const data = JSON.parse(res.data);

        setPost(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <>
      {props.loggedout ? <LoggedOutHeader /> : ""}
      <section className="about">
        <article className="post">
          <div className="banner">
            <img
              className="post__thumbnail"
              alt="post tumbnail"
              src={post.thumbnail}
            />

            <h1 className="post__title">{post.post_title}</h1>
          </div>

          <div
            className="post__content text-gray"
            dangerouslySetInnerHTML={{ __html: post.post_content }}
          ></div>
        </article>
      </section>
      <BottomSection />
    </>
  );
}
