import React, { createContext, useState } from "react";
import MODULE_API from "../API/api";
import jwt from "jsonwebtoken";

export const AuthContext = createContext([{}, {}]);

const API = new MODULE_API();

const AuthContextProvider = ({ children }) => {
  const [state, setState] = useState({
    token: localStorage.getItem("token"),
    userID: localStorage.userID,
    regisrationComplete: localStorage.complete === "true" ? true : false,
    firstname: localStorage.firstname,
  });

  async function Login(email, psw) {
    const api_res = await API.login({ email: email, password: psw });

    if (api_res.status === 200) {
      localStorage.setItem("token", api_res.token);
      const raw_decoded = await jwt.decode(api_res.token);
      const userData = JSON.parse(raw_decoded.data);
      localStorage.userID = userData.userID;
      localStorage.complete = userData.registrationComplete;
      localStorage.firstname = userData.firstname;

      setState({
        token: api_res.token,
        userID: raw_decoded.userID,
        regisrationComplete: userData.registrationComplete,
        firstname: userData.firstname,
      });

      return api_res.status;
    }

    return api_res.status;
  }

  async function Register(username, password, email) {
    const api_res = await API.register({
      username: username,
      password: password,
      email: email,
    });
    return api_res;
  }

  function Logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("localStorage.complete");
    localStorage.removeItem("userID");
    localStorage.removeItem("firstname");
    setState({ token: undefined });
    window.location.href = "/";
  }

  async function RegisterUserData(userProps) {
    const raw_result = await API.setInitialData(userProps);
    const result = JSON.parse(raw_result);

    if (result.status === 200) {
      localStorage.complete = true;
      setState((prevState) => ({
        ...prevState,
        regisrationComplete: true,
      }));
      localStorage.firstTimeHome = true;

      return true;
    } else {
      return false;
    }
  }

  return (
    <AuthContext.Provider
      value={[
        state,
        {
          login: Login,
          logout: Logout,
          register: Register,
          registerUserData: RegisterUserData,
          setState: setState,
        },
      ]}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
