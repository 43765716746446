import React, { useEffect, useState } from "react";
import Users from "../Users";
import Loading from "../helpers/Loading";
import BottomSection from "../BottomSection";
import { Link } from "react-router-dom";

import mobile from "../../assets/images/match.svg";
import check from "../../assets/images/check.svg";
import arrow from "../../assets/images/arrow.svg";
import heart from "../../assets/images/logo_heart.svg";

import Premium from "../Premium";
import Post from "../Post";
import axios from "axios";

import WelcomePopup from "./WelcomePopup";

export default function Frontpage(props) {
  const [image, setImage] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (props.loggedout) {
      window.location.href = "/login";
    }
    navigator.geolocation.getCurrentPosition(function (position) {
      const { longitude, latitude } = position.coords;
      const headers = { Authorization: "Barer " + props.token };
      axios
        .post(
          process.env.REACT_APP_URL + "/user/position ",
          {
            long: longitude,
            lat: latitude,
          },
          { headers: headers }
        )
        .then((data) => {});
    });

    axios.get(process.env.REACT_APP_URL + "/data/frontpage").then((res) => {
      const data = JSON.parse(res.data);

      if (data !== null) {
        setImage(data.image[0].image.url);
        setTitle(data.title);


        let index = 0;
        let first = true;
        const img = document.getElementById("bannerBG");
        const img2 = document.getElementById("bannerBG2");

        if (img) {
          img.style.opacity = 1;
          img2.style.opacity = 0;
          setInterval(() => {
            if (data.image[index + 1]?.image.url) {
              index++;
            } else {
              index = 0;
            }
            if (first) {
              first = false;
              img.style.opacity = 0;
              img2.style.opacity = 1;
              img2.src = data.image[index].image.url;
            } else {
              first = true;
              img.style.opacity = 1;
              img2.style.opacity = 0;
              img.src = data.image[index].image.url;
            }
          }, 7000);
        }
      }
    });
  }, [props.token]);

  return (
    <div
      className="frontpage-continer"
      style={props.loggedout ? { marginTop: "80px" } : {}}
    >
      <article className="banner">
        {image ? <img id="bannerBG" alt="banner" src={image} /> : <Loading />}
        {image ? <img id="bannerBG2" alt="banner" src={image} /> : <Loading />}

        <div>
          <h1
  dangerouslySetInnerHTML={{__html: title}}
  className="fade-in"
  />
          <Link className="cta" to={props.loggedout ? "/login" : "/search"}>
            Hitta singlar
          </Link>
        </div>
        <div className="premium hide-small fade-in delay">
          <h1>Uppgradera till premium!</h1>
          <p>
            Uppgradera till Premium för att se dina likes,<br></br> besökare och
            matchningar.
          </p>
          <div className="checks">
            <img src={mobile} alt="mobile" />
            <div>
              <div>
                <img src={check} alt="checkmark" />
                <p>Se dina likes, besökare och matchningar.</p>
              </div>
              <div>
                <img src={check} alt="checkmark" />
                <p>Bli invjuden till våra VIP-event.</p>
              </div>
              <div>
                <img src={check} alt="checkmark" />
                <p>Större chans att träffa din drömpartner.</p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <div className="content">
        {props.loggedout ? (
          <h1>
            Välkommen till Nelale{" "}
            <img className="small-heart" src={heart} alt="heart" />
          </h1>
        ) : (
          <h1>
            Välkommen till Nelale{" "}
            <img className="small-heart" src={heart} alt="heart" />{" "}
            {localStorage.firstname[0].toUpperCase() +
              localStorage.firstname.substring(1)}{" "}
          </h1>
        )}

        {/* Users sort/listing side swiper */}
        <p className="text-bold">
          Dejtingsidan som ökar dina chanser att träffa din blivande partner!
        </p>
        <button
          className="goRight"
          onClick={(e) => {
            const container = document.getElementById("users");
            if (window.innerWidth > 800) {
              container.scrollTo({
                left: container.scrollLeft + window.innerWidth / 2,
                behavior: "smooth",
              });
            } else {
              container.scrollTo({
                left: container.scrollLeft + window.innerWidth / 2,
                behavior: "smooth",
              });
            }
          }}
        >
          <img src={arrow} alt="navigation" />
        </button>
        <Users loggedout={props.loggedout} token={props.token} />
      </div>

      <div className="blue">
        <h2>Event & händelser</h2>
        <div className="posts">
          <Post token={props.token} latest={true} exerpt={true} />
        </div>
        <Link className="all-events" to="/archive">
          Hitta alla event här
        </Link>
      </div>
      {props.loggedout ? "" : <Premium />}
      <BottomSection />

      {/* Welcome popup */}
      {localStorage.firstTimeHome === "true" ? <WelcomePopup /> : ""}
    </div>
  );
}
