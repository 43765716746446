import "./DropSelect.css";
import Arrow from "../../../assets/images/arrow.svg";
import React, { useState } from "react";

export default function DropSelect(props) {
  const [open, setOpen] = useState(true);

  function change(e) {
    const value = e.target.parentElement.childNodes[1].textContent;
    if (props.data.length > 2) {
      e.target.checked ? props.set(value) : props.remove(value);
    }
  }

  return props.data ? (
    <div className="dropSelect">
      <div className={open ? "items open" : "items"}>
        <img
        alt='arrow'
          onClick={() => {
            setOpen(!open);
          }}
          src={Arrow}
        />
        {props.data.map((item) => {
          return (
            <label key={item[props.selector]} className="item">
              <input
                checked={
                  props.selected
                    ? props.selected.find((i) => {
                        return i[props.selector] === item[props.selector];
                      })
                    : ""
                }
                onChange={(e) => {
                  change(e);
                }}
                type="checkbox"
              />
              {item[props.selector]}
            </label>
          );
        })}
      </div>
    </div>
  ) : (
    ""
  );
}
