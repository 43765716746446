import { useEffect, useState } from "react";
import MODULE_API from "../API/api";
import Loading from "./helpers/Loading";
import { Link } from "react-router-dom";

const API = new MODULE_API();

function get_excerpt(string, amount) {
  const splitted = string.split(" ");
  let data = "";
  for (let index = 0; index < amount; index++) {
    if (splitted[index]) {
      data += splitted[index] + " ";
    }
  }
  return data;
}

export default function Posts(props) {
  const [posts, setPosts] = useState();

  useEffect(() => {
    async function getData() {
      const raw_data = await API.posts(props.token);
      const data = JSON.parse(raw_data);
      setPosts(data);
    }
    getData();
  }, [props.token]);

  return posts ? (
    <section className="posts">
      {props.hideIntro ? "" : <h2>Event & händelser</h2>}
      {posts.map((post) => {
        return (
          <article className="post" key={post.ID}>
            <img
              className="post__thumbnail"
              src={post.thumbnail}
              alt="thumbnail"
            />
  
            <h3
              className="post__title"
              dangerouslySetInnerHTML={{ __html: post.post_title }}
            ></h3>

            <div
              className="post__content"
              dangerouslySetInnerHTML={{
                __html: get_excerpt(post.post_content, 15),
              }}
            ></div>

            <Link to={"/post/" + post.ID} className="post__readmore">
              Läs mer
            </Link>
          </article>
        );
      })}
    </section>
  ) : (
    <Loading />
  );
}
