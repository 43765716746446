import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "./helpers/Loading";
import altImg from "../assets/images/no-image.png";
import { AuthContext } from "../context/authContext";
import needle from "../assets/images/needle.svg";
import CalculateDistance from "./helpers/Distance";

import MODULE_API from "../API/api";
import { useContext } from "react";
import cross from "../assets/images/cross.svg";
const API = new MODULE_API();

export default function Users(props) {
  const [users, setUsers] = useState(false);
  const [open, setOpen] = useState(false);

  const [ctx, change] = useContext(AuthContext);
  const [cords, setCords] = useState({});
  const [subscriber, setSubscriber] = useState(false);

  useEffect(() => {
    async function getData() {
      const response = await API.userPreview(props.token);
      const data = await JSON.parse(response);
      const users = JSON.parse(data.data);

      if (data.premium) {
        setSubscriber(true);
      }

      if (!props.loggedout) {
        navigator.geolocation.getCurrentPosition(function (position) {
          setCords({
            long: position.coords.longitude,
            lat: position.coords.latitude,
          });
        });
      }
      setUsers(users);
    }

    getData();
  }, [props.token]);
  function close() {
    localStorage.removeItem("firstTimeHome");
    setOpen(false);
  }
  return users ? (
    <div className="users" id="users">
      {users.map((user) => {
        const distance = Math.round(
          CalculateDistance(
            parseInt(cords.long),
            parseInt(cords.lat),
            parseInt(user.longitude),
            parseInt(user.latitude)
          )
        );

        if (
          parseInt(user.id) !== 1 &&
          parseInt(user.id) !== parseInt(localStorage.userID)
        ) {
          return (
            <div key={user.id}>
              {open ? (
                props.loggedout ? (
                  <div className="welcome-popup">
                    <div className="popup">
                      <img
                        onClick={() => {
                          close();
                        }}
                        className="cross"
                        src={cross}
                        alt="cross"
                      />
                      <h1>
                        Vänligen logga in eller skapa konto för att se denna
                        profil
                      </h1>
                      <div id="non-reg-users">
                        <Link className="button" to={"/login/"}>
                          Logga in
                        </Link>
                        <Link
                          className="button alternate register-btn"
                          to={"/register/"}
                        >
                          Registrera
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="welcome-popup">
                    <div className="popup">
                      <img
                        onClick={() => {
                          close();
                        }}
                        className="cross"
                        src={cross}
                        alt="cross"
                      />
                      <h1>
                        Vad god uppgradera till Premium för att se andras
                        konton.
                      </h1>
                      <div id="non-reg-users">
                        <Link
                          className="button alternate register-btn"
                          to={"/checkout/"}
                        >
                          Uppgradera
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                ""
              )}
              {props.loggedout ? (
                <button
                  key={user.id}
                  className={user.subscriber ? "subscriber user" : "user"}
                  onClick={() => {
                    console.log("click");
                    setOpen(true);
                  }}
                  style={{ border: "none" }}
                >
                  {user.subscriber ? (
                    <div className="user_subscriber">Premium</div>
                  ) : (
                    ""
                  )}
                  {user.images ? (
                    <img
                      style={
                        props.loggedout || !subscriber
                          ? { filter: "blur(5px)" }
                          : {}
                      }
                      src={user.images[0].picture.url}
                      alt="profile-pic"
                    />
                  ) : (
                    <img src={altImg} alt="altimg" />
                  )}
                  <p className="text-bold">
                    {user.name.charAt(0).toUpperCase() + user.name.substring(1)}
                    <b>
                      {distance > 0 ? (
                        <>
                          , {distance} km{" "}
                          <img src={needle} alt="needle" className="needle" />
                        </>
                      ) : (
                        ""
                      )}
                    </b>
                  </p>
                  <p className="text-regular">
                    {user.age}, {user.city}
                  </p>
                </button>
              ) : subscriber ? (
                <Link
                  to={"/user/" + user.id}
                  key={user.id}
                  className={user.subscriber ? "subscriber user" : "user"}
                >
                  {user.subscriber ? (
                    <div className="user_subscriber">Premium</div>
                  ) : (
                    ""
                  )}
                  {user.images ? (
                    <img
                      style={
                        props.loggedout || !subscriber
                          ? { filter: "blur(5px)" }
                          : {}
                      }
                      src={user.images[0].picture.url}
                      alt="profile-pic"
                    />
                  ) : (
                    <img src={altImg} alt="altimg" />
                  )}
                  <p className="text-bold">
                    {user.name.charAt(0).toUpperCase() + user.name.substring(1)}
                    <b>
                      {distance > 0 ? (
                        <>
                          , {distance} km{" "}
                          <img src={needle} alt="needle" className="needle" />
                        </>
                      ) : (
                        ""
                      )}
                    </b>
                  </p>
                  <p className="text-regular">
                    {user.age}, {user.city}
                  </p>
                </Link>
              ) : (
                <div
                  onClick={() => {
                    console.log("opening alert");
                    setOpen(true);
                  }}
                  className="user"
                >
                  {user.images ? (
                    <img
                      style={
                        props.loggedout || !subscriber
                          ? { filter: "blur(5px)" }
                          : {}
                      }
                      src={user.images[0].picture.url}
                      alt="profile-pic"
                    />
                  ) : (
                    <img src={altImg} alt="altimg" />
                  )}
                  <p className="text-bold">
                    {user.name.charAt(0).toUpperCase() + user.name.substring(1)}
                    <b>
                      {distance > 0 ? (
                        <>
                          , {distance} km{" "}
                          <img src={needle} alt="needle" className="needle" />
                        </>
                      ) : (
                        ""
                      )}
                    </b>
                  </p>
                  <p className="text-regular">
                    {user.age}, {user.city}
                  </p>
                </div>
              )}
            </div>
          );
        }
        return "";
      })}
    </div>
  ) : (
    <Loading />
  );
}
