import logo from "../../assets/logo/logo.svg";
import logo_white from "../../assets/logo/logo-vit.svg";

export default function Logo(props) {
  return (
    <img
      className="logo"
      style={props.style}
      src={props.white ? logo_white : logo}
      alt="logo"
    />
  );
}
