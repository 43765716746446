import React, { useCallback, createContext, useEffect, useState } from "react";
import SubscriptionPopup from "../Components/pages/Matches/SubscriptionPopup";
import MatchPopup from "../Components/pages/Matches/MatchPopup";
import LanguagePopup from "../Components/helpers/LanguagePopup";
import axios from "axios";

export const PopupContext = createContext([{}, {}]);

const PopupContextProvider = (props) => {
  const [state, setState] = useState({
    view: false,
    target: "",
    options: {},
  });

  const [transel, setTransel] = useState();

  const [products, setProducts] = useState([]);

  function showPopup(show, popup, data = "") {
    let targetPopup = "";
    switch (popup) {
      case "subscribe":
        targetPopup = "subscribe";
        break;

      case "match":
        targetPopup = "match";
        break;
      case "lang":
        targetPopup = "lang";
        break;
      default:
        targetPopup = "";
        break;
    }

    setState({
      view: show,
      target: targetPopup,
      options: data,
    });
  }

  const newPopup = useCallback(
    async (userid) => {
      const url = process.env.REACT_APP_URL + "/user/data";
      const headers = { Authorization: "Barer " + props.token };
      const userData = await axios.post(
        url,
        { userID: userid },
        { headers: headers }
      );

      const parsed = JSON.parse(userData.data);

      const picUrl1 = parsed.data.pictures
        ? parsed.data.pictures[0].picture.url
        : false;

      const userData2 = await axios.post(
        url,
        { userID: props.id },
        { headers: headers }
      );

      const parsed2 = JSON.parse(userData2.data);

      const picUrl2 = parsed2.data.pictures
        ? parsed2.data.pictures[0].picture.url
        : false;

      setState({
        view: true,
        target: "match",
        options: {
          pic1: picUrl1,
          pic2: picUrl2,
          room: parsed.data.room,
        },
      });
    },
    [props.id, props.token]
  );

  const timer = useCallback(async () => {
    setInterval(async () => {
      const url = process.env.REACT_APP_URL + "/user/matches";
      const headers = { Authorization: "Barer " + props.token };
      const data = await axios.get(url, { headers: headers });
      const matches = JSON.parse(data.data).matches;
      const mm = matches;
      if (mm) {
        const tempArr = mm.map((el) => {
          return el.match;
        });

        const arr = JSON.parse(localStorage.matches);

        let difference = tempArr.filter((x) => !arr.includes(x));
        difference.forEach((newMatch) => {
          newPopup(parseInt(newMatch));
        });
        localStorage.matches = JSON.stringify(tempArr);
      }
    }, 1000 * 5);
  }, [props.token, newPopup]);

  useEffect(() => {
    async function get() {
      const url = process.env.REACT_APP_URL + "/user/matches";
      const headers = { Authorization: "Barer " + props.token };
      const data = await axios.get(url, { headers: headers });
      const matches = JSON.parse(data.data).matches;
      const m = matches;

      if (m) {
        const newArr = m.map((element) => {
          return element.match;
        });
        localStorage.matches = JSON.stringify(newArr);
      } else {
        localStorage.matches = JSON.stringify([]);
      }
      timer();
    }

    async function getProducts() {
      axios.get(process.env.REACT_APP_URL + "/data/products").then((data) => {
        const products = JSON.parse(data.data);
        setProducts(products);
      });
    }
    get();
    getProducts();
  }, [props.token, timer]);

  return (
    <PopupContext.Provider
      value={[
        state,
        {
          showPopup: showPopup,
          setState: setState,
        },
      ]}
    >
      {props.children}
      <div
        onClick={(e) => {
          if (e.target.classList.contains("popupOverlay")) {
            showPopup(false);
          }
        }}
        className={state.view ? " popupOverlay" : ""}
      >
        {state.view && state.target === "subscribe" ? (
          <SubscriptionPopup
            products={products}
            close={() => {
              showPopup(false);
            }}
          />
        ) : (
          ""
        )}
        {state.view && state.target === "lang" ? (
          <LanguagePopup
            transel={transel}
            setTransel={(el) => {
              console.clear();

              setTransel(el);
            }}
            close={() => {
              showPopup(false);
            }}
          />
        ) : (
          ""
        )}

        {state.view && state.target === "match" ? (
          <MatchPopup
            data={state.options}
            close={() => {
              showPopup(false);
            }}
          />
        ) : (
          ""
        )}
      </div>
    </PopupContext.Provider>
  );
};

export default PopupContextProvider;
