import React from "react";

export default function Loading(props) {
  return (
    <div className={props.white ? "loading white" : "loading"}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
