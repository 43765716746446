import React, { useEffect, useState } from "react";
import Arrow from "../../assets/images/arrow.svg";
import Logo from "../helpers/Logo";
import Loading from "../helpers/Loading";

import MODULE_API from "../../API/api";

const API = new MODULE_API();

export default function ProfileSetup(props) {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    country: [],
    hobbies: [],
    religions: [],
    genders: [],
  });

  const [userData, setUserData] = useState({
    sliderVal: 18,
    country: undefined,
    city: undefined,
    gender: undefined,
    religion: undefined,
    hobbie: [],
    firstname: "",
    lastname: "",
  });

  useEffect(() => {
    async function getSetupData() {
      const raw_data = await API.registrationData();
      const parsed = JSON.parse(raw_data);
      const data_fields = JSON.parse(parsed.data);
      setFields({
        country: data_fields.country,
        hobbies: data_fields.hobbies,
        religions: data_fields.religions,
        genders: data_fields.genders,
      });
    }

    getSetupData();
  }, []);

  function DoGender(e) {
    const container = Array.from(document.getElementById("genders").children);
    container.forEach((child) => {
      if (child === e.target) {
        child.classList.add("active");
      } else {
        child.classList.remove("active");
      }
    });
    setUserData((prevstate) => ({
      ...prevstate,
      gender: e.target.innerHTML,
    }));
  }

  function DoKids(e) {
    const container = Array.from(document.getElementById("kids").children);
    container.forEach((child) => {
      if (child === e.target) {
        child.classList.add("active");
      } else {
        child.classList.remove("active");
      }
    });
    setUserData((prevstate) => ({
      ...prevstate,
      kids: e.target.innerHTML,
    }));
  }

  switch (step) {
    case 0:
      return (
        <div className="profileSetup" style={{ justifyContent: "flex-start" }}>
          <button
            className="back"
            onClick={() => {
              localStorage.removeItem("token");
              window.location.href = "/";
            }}
          >
            <img src={Arrow} alt="back" />
            <p className="hide-small">Logga ut</p>
          </button>
          <Logo white />
          <div className="step-progress"></div>
          <h1>Välkommen till Nelale!</h1>
          <p>Låt oss börja med att skapa en profil åt dig.</p>
          <p style={{ margin: "4em 0 1em 0", fontSize: "20px" }}>
            Vad heter du?
          </p>
          <input
            placeholder="Förnamn"
            value={userData.firstname}
            onChange={(e) => {
              setUserData((prevState) => ({
                ...prevState,
                firstname: e.target.value,
              }));

              localStorage.firstname = e.target.value;
            }}
          />
          <input
            placeholder="Efternamn"
            value={userData.lastname}
            onChange={(e) => {
              setUserData((prevState) => ({
                ...prevState,
                lastname: e.target.value,
              }));
            }}
          />
          <p style={{ fontSize: "14px", color: "#929191", margin: "0.5em 0" }}>
            Ditt efternamn kommer inte visas på hemsidan.
          </p>
          <button
            className={
              userData.firstname && userData.lastname ? "" : "disabled"
            }
            disabled={userData.firstname && userData.lastname ? false : true}
            onClick={() => {
              setStep(step + 1);
            }}
          >
            GÅ VIDARE
          </button>
        </div>
      );

    case 1:
      return (
        <div className="profileSetup">
          <div className="step-progress two"></div>
          <button
            className="back"
            onClick={() => {
              setStep(step - 1);
            }}
          >
            <img src={Arrow} alt="back" />
            <p className="hide-small">Tillbaka</p>
          </button>
          <h3>Din ålder</h3>
          <p>{userData.sliderVal}</p>
          <input
            min={18}
            max={65}
            value={userData.sliderVal}
            onChange={(e) => {
              const value = e.target.value;
              setUserData((prevState) => ({
                ...prevState,
                sliderVal: value,
              }));
            }}
            type="range"
          />
          <button
            onClick={() => {
              setStep(step + 1);
            }}
          >
            GÅ VIDARE
          </button>
        </div>
      );

    case 2:
      return (
        <div className="profileSetup">
          <div className="step-progress three"></div>
          <button
            className="back"
            onClick={() => {
              setStep(step - 1);
            }}
          >
            <img src={Arrow} alt="back" />
            <p className="hide-small">Tillbaka</p>
          </button>
          <h3>Ange kön</h3>
          <p>Jag är en</p>
          <div id="genders">
            {fields.genders.map((gender) => {
              return (
                <label
                  key={gender.gender}
                  className={
                    userData.gender === gender.gender.toUpperCase()
                      ? "active"
                      : ""
                  }
                  onClick={(e) => {
                    DoGender(e);
                  }}
                >
                  {gender.gender.toUpperCase()}
                </label>
              );
            })}
          </div>

          <button
            className={userData.gender ? "" : "disabled"}
            disabled={userData.gender ? false : true}
            onClick={async () => {
              setStep(step + 1);
            }}
          >
            GÅ VIDARE
          </button>
        </div>
      );

    case 3:
      return (
        <div className="profileSetup">
          <div className="step-progress four"></div>
          <button
            className="back"
            onClick={() => {
              setStep(step - 1);
            }}
          >
            <img src={Arrow} alt="back" />
            <p className="hide-small">Tillbaka</p>
          </button>
          <h3>Vilken stad bor du i</h3>

          <input
            type="text"
            value={userData.city}
            onChange={(e) => {
              setUserData((prevSate) => ({
                ...prevSate,
                city: e.target.value,
              }));
            }}
          />

          <button
            className={userData.city ? "" : "disabled"}
            disabled={userData.city ? false : true}
            onClick={() => {
              setStep(step + 1);
            }}
          >
            GÅ VIDARE
          </button>
        </div>
      );

    //Skriv stad
    case 4:
      return (
        <div className="profileSetup">
          <div className="step-progress five"></div>
          <button
            className="back"
            onClick={() => {
              setStep(step - 1);
            }}
          >
            <img src={Arrow} alt="back" />
            <p className="hide-small">Tillbaka</p>
          </button>
          <h3>Vad är din religion</h3>
          <select
            onChange={(e) => {
              setUserData((prevState) => ({
                ...prevState,
                religion: e.target.value,
              }));
            }}
          >
            {userData.religion ? (
              <option>{userData.religion}</option>
            ) : (
              <option>Välj religion</option>
            )}
            {fields.religions.map((rel) => {
              if (rel.religion_name !== userData.religion) {
                return (
                  <option key={rel.religion_name} value={rel.religion_name}>
                    {rel.religion_name}
                  </option>
                );
              }
              return "";
            })}
          </select>
          <button
            className={userData.religion ? "" : "disabled"}
            disabled={userData.religion ? false : true}
            onClick={() => {
              setStep(step + 1);
            }}
          >
            GÅ VIDARE
          </button>
        </div>
      );

    case 5:
      return (
        <div className="profileSetup">
          <div className="step-progress six"></div>
          <button
            className="back"
            onClick={() => {
              setStep(step - 1);
            }}
          >
            <img src={Arrow} alt="back" />
            <p className="hide-small">Tillbaka</p>
          </button>
          <h3>Vad gillar du att göra på fritiden? Välj minst 3 alternativ</h3>
          <div className="hobbies">
            {fields.hobbies.map((hobbie) => {
              return (
                <label
                  key={hobbie.hobbie_name}
                  onClick={(e) => {
                    e.target.classList.toggle("active");
                    const value = hobbie.hobbie_name;
                    console.log(value);
                    if (userData.hobbie.includes(value)) {
                      console.log("add");
                      setUserData((prevstate) => ({
                        ...prevstate,
                        hobbie: userData.hobbie.filter((hob) => {
                          return hob === hobbie ? false : true;
                        }),
                      }));
                    } else {
                      console.log("remove");
                      setUserData((prevstate) => ({
                        ...prevstate,
                        hobbie: [...userData.hobbie, value],
                      }));
                    }

                    console.log(userData.hobbie);
                  }}
                >
                  {hobbie.hobbie_name.toUpperCase()}
                </label>
              );
            })}
          </div>
          <button
            className={userData.hobbie.length > 2 ? "" : "disabled"}
            disabled={userData.hobbie.length > 2 ? false : true}
            onClick={() => {
              setStep(step + 1);
            }}
          >
            GÅ VIDARE
          </button>
        </div>
      );

    case 6:
      return (
        <div className="profileSetup">
          <div className="step-progress six"></div>
          <button
            className="back"
            onClick={() => {
              setStep(step - 1);
            }}
          >
            <img src={Arrow} alt="back" />
            <p className="hide-small">Tillbaka</p>
          </button>
          <h3>Vilket land bor du i?</h3>
          <select
            value={userData.country}
            onChange={(e) => {
              setUserData((prevstate) => ({
                ...prevstate,
                country: e.target.value,
              }));
            }}
          >
            <option key="selectstad">Välj land</option>

            {fields.country.map((country) => {
              return (
                <option key={country.countryname} value={country.countryname}>
                  {country.countryname}
                </option>
              );
            })}
          </select>
          <button
            className={userData.country ? "" : "disabled"}
            disabled={userData.country ? false : true}
            onClick={() => {
              setStep(step + 1);
            }}
          >
            GÅ VIDARE
          </button>
        </div>
      );

    case 7:
      return (
        <div className="profileSetup">
          <div className="step-progress eight"></div>
          <button
            className="back"
            onClick={() => {
              setStep(step - 1);
            }}
          >
            <img src={Arrow} alt="back" />
            <p className="hide-small">Tillbaka</p>
          </button>
          <h3>Har du barn?</h3>
          <div id="kids">
            <label
              className={userData.kids === "JA" ? "active" : ""}
              onClick={(e) => {
                DoKids(e);
              }}
            >
              JA
            </label>
            <label
              className={userData.kids === "NEJ" ? "active" : ""}
              onClick={(e) => {
                DoKids(e);
              }}
            >
              NEJ
            </label>

            <label
              className={userData.kids === "VILL EJ UPPGE" ? "active" : ""}
              onClick={(e) => {
                DoKids(e);
              }}
            >
              VILL EJ UPPGE
            </label>
          </div>

          <button
            className={userData.kids ? "" : "disabled"}
            disabled={userData.kids ? false : true}
            onClick={async () => {
              setLoading(true);
              const res = await props.change.registerUserData(userData);

              if (res)
                props.change.setState((prevSate) => {
                  return {
                    ...prevSate,
                    registrationComplete: true,
                  };
                });
            }}
          >
            {loading ? <Loading /> : "SLUTFÖR"}
          </button>
        </div>
      );

    default:
      return "";
  }
}
