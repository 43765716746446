import React, { useEffect, useState } from "react";
import MODULE_API from "../../../API/api";
import Loading from "../../helpers/Loading";

import Form from "./Form";
import SearchResults from "./SearchResults";

const API = new MODULE_API();

export default function Search(props) {
  const [loading, setLoading] = useState(true);
  const [alternatives, setAlternatives] = useState([]);

  const [long, setLong] = useState(false);
  const [lat, setLat] = useState(false);

  const [results, setResults] = useState(false);

  useEffect(() => {
    async function fetchData() {
      //Fetach and parse data
      const data_raw = await API.registrationData();
      const data = JSON.parse(data_raw);
      const searchOptions = JSON.parse(data.data);
      searchOptions.religions.splice(
        searchOptions.religions.indexOf(
          searchOptions.religions[searchOptions.religions.length - 1]
        ),
        1
      );

      //Add data to state
      setAlternatives(searchOptions);
      setLoading(false);
    }
    fetchData();
  }, [props.token]);

  return loading ? (
    <Loading />
  ) : (
    <section className="search">
      <h1 className="hide-large">Ny sökning</h1>

      <div className="the-form">
        <Form
          alternatives={alternatives}
          token={props.token}
          setResults={(data) => {
            setResults(data);
          }}
          setCords={(data) => {
            setLong(data.long);
            setLat(data.lat);
          }}
        />
      </div>
      {results ? <SearchResults long={long} lat={lat} data={results} /> : ""}
    </section>
  );
}
