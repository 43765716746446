import React from "react";
import { Link } from "react-router-dom";
import "./Searchresults.css";
import noImage from "../../../assets/images/no-image.png";
import CalculateDistance from "../../helpers/Distance";

import needle from "../../../assets/images/needle.svg";
import { search } from "../../../assets/images/icons";

export default function SearchResults(props) {
  const blur = parseInt(localStorage.firstSearch);

  return props.data ? (
    <>
      <h1>Förslag på profiler som kan matcha dig</h1>
      <p style={{ textAlign: "center", marginTop: "0.5em" }}>
        {props.data.length > 0 ? "" : "Inga resultat hittades."}
      </p>
      <div className="searchResults">
        {props.data.map((user) => {
          const distance = Math.round(
            CalculateDistance(
              parseInt(props.long),
              parseInt(props.lat),
              parseInt(user.long),
              parseInt(user.lat)
            )
          );
          return (
            <Link key={user.id} to={"/user/" + user.id}>
              <img
                alt="profile"
                className={blur == true ? "blur" : ""}
                src={user.picture ? user.picture : noImage}
              />
              <p className="text-bold">
                {user.name}
                <br className="hide-large"></br>
                <b>
                  {distance > 0 ? (
                    <>
                      <label className="hide-small">,</label> {distance} km{" "}
                      <img src={needle} className="needle" alt="needle" />
                    </>
                  ) : (
                    <>
                      <label className="hide-small">,</label>0 km
                      <img src={needle} className="needle" alt="needle" />
                    </>
                  )}
                </b>
              </p>
              <p>
                {user.age} år{user.city ? ", " + user.city : ""}
              </p>
            </Link>
          );
        })}
      </div>
    </>
  ) : (
    ""
  );
}
