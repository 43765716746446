import React from "react";
import Pictures from "./Pictures";
import TextInfo from "./Textinfo";

export default function Profile(props) {
  return (
    <section className="my_profile">
      <h2>Min profil</h2>
      <p>Lägg upp 1 - 4 bilder på dig själv.</p>
      <Pictures token={props.token} />
      <TextInfo />
    </section>
  );
}
