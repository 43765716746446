import { useEffect, useState } from "react";
import MODULE_API from "../../../API/api";
import noImage from "../../../assets/images/no-image.png";
import { Link } from "react-router-dom";
import Loading from "../../helpers/Loading";

const API = new MODULE_API();

export default function UserDisplay(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    likes: [],
    matches: [],
    visitors: [],
  });

  useEffect(() => {
    async function getData() {
      const raw_data = await API.getUsersByIds(props.token, {
        likes: props.likes,
        visitors: props.visitors,
        matches: props.matches,
      });

      const data = raw_data.data;
      setData({
        likes: data.likes,
        matches: data.matches,
        visitors: data.visitors,
      });
      setLoading(false);
    }

    getData();
  }, [props.token, props.likes, props.visitors, props.matches]);

  function userCard(user, noLink = false) {
    if (!noLink) {
      return (
        <div key={user.name}>
          <img
            src={user.picture.picture ? user.picture.picture.url : noImage}
            alt="missing"
          />
          <p>{user.name}</p>
          <p className="text-gray">
            {user.age}, {user.city}
          </p>
        </div>
      );
    }
    {
      return (
        <Link key={user.name} to={"/user/" + user.id}>
          <img
            src={user.picture.picture ? user.picture.picture.url : noImage}
            alt="missing"
          />
          <p>{user.name}</p>
          <p className="text-gray">
            {user.age}, {user.city}
          </p>
        </Link>
      );
    }
  }

  if (!loading) {
    switch (props.view) {
      case 0:
        if (data.likes.length > 0) {
          return (
            <div className={props.premium ? "userlist" : "userlist blur"}>
              {data.likes.map((vis) => {
                return userCard(vis, props.premium);
              })}
            </div>
          );
        } else {
          return (
            <div className="userlist">
              <h1>Du har inga likes.</h1>
            </div>
          );
        }

      case 1:
        if (data.visitors.length > 0) {
          return (
            <div className={props.premium ? "userlist" : "userlist blur"}>
              {data.visitors.map((vis) => {
                return userCard(vis);
              })}
            </div>
          );
        } else {
          return (
            <div className="userlist">
              <h1>Du har inga besökare.</h1>
            </div>
          );
        }

      case 2:
        if (data.matches.length > 0) {
          return (
            <div className="userlist">
              {data.matches.map((vis) => {
                return userCard(vis, true);
              })}
            </div>
          );
        } else {
          return (
            <div className="userlist">
              <h1>Du har inga matchningar.</h1>
            </div>
          );
        }

      default:
        return "";
    }
  } else {
    return <Loading />;
  }
}
