import React, { useEffect, useState } from "react";
import MODULE_API from "../../../API/api";
import UploadPicture from "./UploadPicture";
import Loading from "../../helpers/Loading";

const API = new MODULE_API();

export default function Pictures(props) {
  const [pictures, setPictures] = useState([]);
  const [loading, setLoading] = useState(true);

  //Fet ch pictures
  useEffect(
    (evt) => {
      async function getPictures() {
        const raw_data = await API.getPictures(props.token);

        const data = JSON.parse(raw_data);

        if (data.data !== null) {
          setPictures(data.data);
        } else {
          setPictures([]);
        }
        setLoading(false);
      }

      getPictures();
    },
    [props.token]
  );

  //Delete picutre
  async function deletePicture(id, rowIndex) {
    const raw_res = await API.deletePicture(props.token, {
      id: id,
      rowIndex: rowIndex,
    });
    const res = JSON.parse(raw_res);
    if (res.data === true) {
      setPictures((prevState) => {
        return prevState.filter((pic) => {
          const index = prevState.indexOf(pic) + 1;
          return index === rowIndex ? false : true;
        });
      });
    } else {
      console.log("Error when deleting profile picture.");
    }
  }

  return loading ? (
    <Loading />
  ) : (
    <div className="pictures">
      {/* Loop trough pictures */}
      {pictures ? (
        pictures.map((pic) => {
          return (
            <div key={pic.picture.id} className="picture">
              <Picture
                deletePicture={deletePicture}
                id={pic.picture.id}
                pic={pic}
                pictures={pictures}
              />
            </div>
          );
        })
      ) : (
        <Loading />
      )}

      {pictures.length < 5 && !loading ? (
        <UploadPicture token={props.token} setPictures={setPictures} />
      ) : (
        ""
      )}
    </div>
  );
}

function Picture(props) {
  return (
    <div>
      <img
        alt={props.pic.picture.alt}
        src={props.pic.picture.url}
        onClick={(e) => {
          if (window.confirm("Are you sure you want to delete this picture?")) {
            e.target.parentElement.parentElement.classList.add("remove");
            props.deletePicture(
              props.id,
              props.pictures.indexOf(props.pic) + 1
            );
          }
        }}
      />
    </div>
  );
}
