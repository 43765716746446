import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../helpers/Loading";

import defaultIMG from "../../assets/images/no-image.png";
import MODULE_API from "../../API/api";
const API = new MODULE_API();

export default function ChatLink(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let userID = 0;
    props.chat.people.forEach((person) => {
      if (parseInt(person.person.username) !== parseInt(localStorage.userID)) {
        userID = person.person.username;
      }
    });

    async function getData() {
      const raw_data = await API.userData(props.token, userID);
      setData(raw_data.data);

      setLoading(false);
    }

    getData();
  }, [props.chat, props.token]);

  return loading ? (
    <Loading />
  ) : (
    <Link to={"/chat/user/" + props.chat.id} className="chat-list-item">
      <img
        alt="profile"
        src={data.pictures ? data.pictures[0].picture.url : defaultIMG}
      />
      <div>
        <h2>
          {data.firstname}, <label className="text-gray">{data.age} år</label>
        </h2>
        <p>
          {props.chat.last_message.text
            ? props.chat.last_message.text
            : "Inga meddelanden"}
        </p>
      </div>
    </Link>
  );
}
