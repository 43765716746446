import React from "react";
import { useEffect, useState } from "react";
import Loading from "./helpers/Loading";
import { Link } from "react-router-dom";
import MODULE_API from "../API/api";
const API = new MODULE_API();

function get_excerpt(string, amount) {
  if (string !== "" && string !== undefined) {
    const splitted = string.split(" ");
    let data = "";
    for (let index = 0; index < amount; index++) {
      if (splitted[index]) {
        data += splitted[index] + " ";
      }
    }

    return data + "...";
  }
  return "";
}

export default function Post(props) {
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      const raw_data = props.latest
        ? await API.getLatestPost()
        : await API.getPost(props.token, { id: props.id });
      const refinedData = JSON.parse(raw_data);
      if (refinedData.length > 0) {
        setPost(refinedData[0]);
      } else {
        setPost(refinedData);
      }
      setLoading(false);
    }

    if (props.post) {
      setPost(props.post);

      setLoading(false);
    } else {
      getData();
    }
  }, [props.token, props.id, props.latest, props.post]);

  return loading ? (
    <Loading />
  ) : (
    <article className="post">
      {props.titleBefore ? (
        <h1 className="post__title">{post.post_title}</h1>
      ) : (
        ""
      )}
      <img
        className="post__thumbnail"
        alt="post tumbnail"
        src={post.thumbnail}
      />
      <div className="post__date" style={{ display: "none" }}>
        {post.date}
      </div>
      {!props.titleBefore ? (
        <h3 className="post__title">{post.post_title}</h3>
      ) : (
        ""
      )}

      <div
        className="post__content"
        dangerouslySetInnerHTML={{
          __html: props.exerpt
            ? get_excerpt(post.post_content, 10)
            : post.post_content,
        }}
      ></div>
      {props.exerpt ? (
        <Link to={"/post/" + post.ID} className="post__readmore">
          Läs mer
        </Link>
      ) : (
        ""
      )}
    </article>
  );
}
