import logo from "../../assets/logo/logo.svg";
import cross from "../../assets/images/cross.svg";
import { useState } from "react";
export default function WelcomePopup(props) {
  const [open, setOpen] = useState(true);
  function close() {
    localStorage.removeItem("firstTimeHome");
    setOpen(false);
  }

  return open ? (
    <div className="welcome-popup">
      <div className="popup">
        <img
          onClick={() => {
            close();
          }}
          className="cross"
          src={cross}
          alt="cross"
        />
        <h1>
          Välkommen till Nelale<br></br>{" "}
          {localStorage.firstname[0].toUpperCase() +
            localStorage.firstname.slice(1)}
          !
        </h1>
        <img className="logo" src={logo} alt="logo" />
        <button
          onClick={() => {
            close();
          }}
        >
          BÖRJA UTFORSKA
        </button>
      </div>
    </div>
  ) : (
    ""
  );
}
