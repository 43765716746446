import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import mobile from "../assets/images/match.svg";
import check from "../assets/images/check.svg";
import axios from "axios";
import ProductCard from "./helpers/productCard";

export default function Premium(props) {
  const [products, setProducts] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    async function getProducts() {
      axios.get(process.env.REACT_APP_URL + "/data/products").then((data) => {
        const products = JSON.parse(data.data);
        setProducts(products);
      });
    }
    getProducts();
  }, []);
  return (
    <section className="premium">
      <div>
        <h1>Uppgradera till premium!</h1>
        <p className="text-gray">
          Uppgradera till Premium för att se dina likes,<br></br> besökare och
          matchningar.
        </p>
        <div>
          <img src={mobile} alt="mobile" />
          <div>
            <div>
              <img src={check} alt="checkmark" />
              <p>Se dina likes, besökare och matchningar.</p>
            </div>
            <div>
              <img src={check} alt="checkmark" />
              <p>Bli inbjuden till våra VIP-event.</p>
            </div>
            <div>
              <img src={check} alt="checkmark" />
              <p>Större chans att träffa din drömpartner.</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="products">
          {products
            ? products.map((prod) => {
                return (
                  <ProductCard
                    click={() => {
                      setSelected(prod.price);
                    }}
                    active={selected === prod.price ? "selected" : ""}
                    key={prod.interval}
                    interval={prod.interval}
                    price={prod.price}
                  />
                );
              })
            : ""}
        </div>
        <Link
          to="/checkout"
          style={{
            background: "black",
            border: "none",
            borderRadius: "10px",
            marginBottom: "5em",
            padding: "15px",
            width: "calc(100% - 4em)",
            fontWeight: "bold",
            color: "white",
          }}
        >
          UPPGRADERA!
        </Link>
      </div>
    </section>
  );
}
