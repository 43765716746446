import React from "react";
import { useState, useEffect } from "react";
import MODULE_API from "../../API/api";
import Loading from "../helpers/Loading";

import BottomSection from "../BottomSection";
import LoggedOutHeader from "../helpers/LoggedOutHeader";

function QuestionAnswer(props) {
  const [open, setOpen] = useState(false);
  return (
    <article
      className="container"
      key={props.data.question}
      onClick={(e) => {
        setOpen(!open);
      }}
    >
      <p className="question">{props.data.question}</p>
      <button className="button-change">{open ? "-" : "+"}</button>
      <div className={open ? " open answer-cont" : "answer-cont"}>
        <p>{props.data.answer}</p>
      </div>
    </article>
  );
}

const API = new MODULE_API();
export default function Faq(props) {
  const [loading, setLoading] = useState(true);
  const [faqs, setFaqs] = useState({});

  useEffect(() => {
    async function getData() {
      const raw_data = await API.faq(props.token);
      const data = JSON.parse(raw_data);
      setFaqs(data);
      setLoading(false);
    }
    getData();
  }, [props.token]);

  return (
    <>
      {props.loggedout ? <LoggedOutHeader /> : ""}
      <div className="faq">
        <h1>Vanliga frågor & svar</h1>

        {loading ? (
          <Loading />
        ) : (
          faqs.map((faq) => {
            return <QuestionAnswer data={faq} />;
          })
        )}
      </div>
      <BottomSection />
    </>
  );
}
