import React, { useEffect, useState } from "react";
import MODULE_API from "../../API/api";
import add from "../../assets/images/add.svg";

const API = new MODULE_API();

export default function ProfilePicturesEdit(props) {
  const [profilePic, setProfilePic] = useState();

  //Fet ch pictures
  useEffect(() => {
    async function getProfilePicture() {
      const raw_data = await API.getProfilePicture(props.token);

      const data = JSON.parse(raw_data);
      if (data.data !== null) {
        setProfilePic(data.data);
      }
    }

    getProfilePicture();
  }, [props.token]);

  //Delete picutre
  async function deleteProfilePicture() {
    const raw_res = await API.deleteProfilePicture(props.token);
    const res = JSON.parse(raw_res);
    if (res.data === true) {
      setProfilePic(undefined);
    } else {
      console.log("Error when deleting profile picture.");
    }
  }

  //Upload file
  async function onFileSelect(file) {
    const formData = new FormData();
    const fileName = Math.random() * 1000 + file.name;
    formData.append(fileName, file);

    const raw_res = await API.sendFile(formData, props.token);
    const res = JSON.parse(raw_res);
    setProfilePic(res.data.url);
  }

  return (
    <section className="editProfile__pictures">
      <p>Lägg upp en till fyra bilder på dig själv.</p>
      <div>
        <div className="picture">
          {profilePic ? (
            <img
              alt="pric"
              src={profilePic}
              onClick={async () => {
                if (
                  window.confirm(
                    "Are you sure you want to delete your profile picture?"
                  )
                ) {
                  await deleteProfilePicture();
                }
              }}
            />
          ) : (
            <div>
              <input
                type="file"
                name="myFile"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(e) => {
                  onFileSelect(e.target.files[0]);
                }}
              />
              <div
                className="picture add"
                onClick={(e) => {
                  e.target.parentElement.parentElement.children[0].click();
                }}
              >
                <img alt="add" src={add} />
              </div>
            </div>
          )}
        </div>

        {/* Loop trough pictures */}

        {/* Add image (only if user has a profile picture) */}
        {profilePic ? (
          <div className="picture add">
            <img alt="add" src={add} />
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
}
