import React from "react";
import instagram from "../assets/logo/instagram.svg";
import Logo from "./helpers/Logo";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import MODULE_API from "../API/api";
const API = new MODULE_API();

export default function BottomSection(props) {
  const [links, setLinks] = useState({
    instagram: "",
    facebook: "",
  });

  useEffect(() => {
    async function getData() {
      const raw_data = await API.getSocialmediaLinks();
      const data = JSON.parse(raw_data.data);
      setLinks({
        instagram: data.instagram,
        facebook: data.facebook,
      });
    }

    getData();
  }, []);

  return (
    <section className="bottom-section">
      <Link to="/">
        <Logo className="logo" white />
      </Link>
      <nav>
        <Link to="/about-us">Om Nelale</Link>
        <Link to="/contact">Kontakta oss</Link>
        <Link to="/faq">Vanliga frågor & svar</Link>
        <Link to="/archive">Event & händelser</Link>
        <Link to="/policy">Integritetspolicy</Link>
      </nav>
      <div
        onClick={() => {
          window.open(links.instagram, "_blank");
        }}
        className="social-media"
      >
        <p>Dela Nelale med dina vänner</p>
        <img src={instagram} alt="instagram" />
      </div>
    </section>
  );
}
