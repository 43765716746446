import { useEffect, useState } from "react";
import MODEL_API from "../../API/api";
import Post from "../Post";
import Loading from "../helpers/Loading";

import LoggedOutHeader from "../helpers/LoggedOutHeader";
import BottomSection from "../BottomSection";

const API = new MODEL_API();

export default function PostPage(props) {
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getData() {
      const raw_data = props.latest
        ? await API.getLatestPost()
        : await API.getPost(props.token, { id: props.id });
      const refinedData = JSON.parse(raw_data);
      if (refinedData.length > 0) {
        setPost(refinedData[0]);
      } else {
        setPost(refinedData);
      }
      setLoading(false);
    }

    getData();
  }, [props.token, props.id, props.latest]);

  return loading ? (
    <Loading />
  ) : (
    <>
      {props.loggedout ? <LoggedOutHeader /> : ""}
      <div className="singlepost">
        <Post post={post} latest={false} />
      </div>
      {props.loggedout ? <BottomSection /> : ""}
    </>
  );
}
