import React, { useState } from "react";

export default function OptionSelect(props) {
  const [used, setUsed] = useState(false);

  return (
    <section className="optionSelect">
      {props.data
        ? props.data.map((option) => {
         
            return option[props.selector] ? (
              <button
                onClick={(e) => {
                  if (option === used) {
                    setUsed(false);

                    props.onChange({
                      name: "clear",
                      target: "gender",
                    });
                  } else {
                    setUsed(option);
                    e.target.value = option[props.selector].toUpperCase();
                    props.onChange(e);
                  }
                }}
                name={props.name}
                className={
                  option === used ||
                  (props.selected === option[props.selector].toUpperCase() && used === false)
                    ? "option selected"
                    : "option"
                }
                key={option[props.selector]}
              >
                {option[props.selector].toUpperCase()}
              </button>
            ) : (
              ""
            );
          })
        : ""}
    </section>
  );
}
