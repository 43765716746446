import { useEffect, useState } from "react";
import ChatLink from "../helpers/ChatLink";
import axios from "axios";
import Loading from "../helpers/Loading";

export default function Chat(props) {
  const [chats, setChats] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getChats() {
      const headers = {
        "Project-ID": process.env.REACT_APP_CHAT_ID,
        "User-Name": parseInt(localStorage.userID),
        "User-Secret": parseInt(localStorage.userID),
      };
      console.log(headers);

      axios
        .get("https://api.chatengine.io/chats/", {
          headers: headers,
        })
        .then((response) => {
          setChats(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }

    getChats();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <section className="chat-list">
      <h1 style={{ padding: "22px" }}>Mina meddelande</h1>
      {chats.length > 0 ? (
        chats.map((chat) => {
          chat.people.filter(
            (obj) =>
              parseInt(obj.person.username) !== parseInt(localStorage.userID)
          );
          return (
            <ChatLink
              key={chat.id}
              token={props.token}
              userID={props.userID}
              chat={chat}
            />
          );
        })
      ) : (
        <p
          style={{ padding: "0 22px", textAlign: "center" }}
          className="text-gray"
        >
          Här var det tomt! Använd Sök för att hitta någon att börja chatta med.
        </p>
      )}
    </section>
  );
}
