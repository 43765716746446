import mobile from "../../../assets/images/match.svg";
import checkmark from "../../../assets/images/check.svg";
import ProductCard from "../../helpers/productCard.js";
import { Link } from "react-router-dom";

function CheckText(props) {
  return (
    <div className="flex checkmark-cont">
      <img alt="check" src={checkmark} />
      <p>{props.text}</p>
    </div>
  );
}

export default function SubscriptionPopup(props) {
  return (
    <section className="subscription-popup">
      <h1>Uppgradera till Premium</h1>
      <p className="text-gray">
        Uppgradera till Premium för att se dina likes, besökare och matchningar.
      </p>
      <div className="flex">
        <img className="phone-pic" alt="mobile-phone" src={mobile} />
        <div className="checkmarks">
          <CheckText text="Se dina likes, besökare och matchningar." />
          <CheckText text="Bli inbjuden till våra VIP-event." />
          <CheckText text="Skicka meddelanden till alla medlemmar." />
        </div>
      </div>

      <div className="flex justify-center">
        {props.products.map((prod) => {
          return (
            <ProductCard
              interval={prod.interval}
              price={prod.price}
              click={(data) => {
                window.location.href =
                  "/checkout#" + props.products.indexOf(prod);
              }}
            />
          );
        })}
      </div>

      <Link
        className="button"
        onClick={() => {
          props.close();
        }}
        to="/checkout"
      >
        UPPGRADERA
      </Link>
      <p
        className="close"
        onClick={() => {
          props.close();
        }}
      >
        INTE NU
      </p>
    </section>
  );
}
