import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../helpers/Loading";
import LoggedOutHeader from "../helpers/LoggedOutHeader";
export default function Reset(props) {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  switch (step) {
    case 0:
      return (
        <>
          {props.loggedout ? <LoggedOutHeader /> : ""}

          <div className="password-reset">
            <h1>Återställ lösenord</h1>
            <p>Var god ange din E-post</p>
            {error ? <p className="error">{error}</p> : ""}
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <button
              onClick={() => {
                if (email !== "") {
                  setLoading(true);
                  axios
                    .post(
                      process.env.REACT_APP_URL + "/bdpwr/v1/reset-password",
                      {
                        email: email,
                      }
                    )
                    .then((res) => {
                      setStep(1);
                      setLoading(false);
                    })
                    .catch((err) => {
                      setError(
                        "Det finns ingen användare som är registrerad under den E-posten"
                      );
                      console.log(err);
                      setLoading(false);
                    });
                } else {
                  alert("Du måste fylla i din e-post");
                }
              }}
              className="button"
            >
              {loading ? <Loading /> : "Skicka"}
            </button>
          </div>
        </>
      );
      break;

    case 1:
      return (
        <>
          {props.loggedout ? <LoggedOutHeader /> : ""}

          <div className="password-reset">
            <h1>Ange kod</h1>
            <p className="success">
              Var god ange koden som skickades till {email}
            </p>
            <input
              placeholder="Verifieringskod"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            <input
              value={password}
              placeholder="Lösenord"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button
              onClick={() => {
                if (email !== "" && password !== "" && code !== "") {
                  setLoading(true);
                  axios
                    .post(
                      process.env.REACT_APP_URL + "/bdpwr/v1/set-password",
                      {
                        email: email,
                        code: code,
                        password: password,
                      }
                    )
                    .then((res) => {
                      window.location.href = "/login";
                    })
                    .catch((err) => {
                      setLoading(false);
                      alert(
                        "Fel uppstod. Var kod kontakta oss för mera information"
                      );
                    });
                } else {
                  alert("Du måste fylla i din e-post");
                }
              }}
              className="button"
            >
              {loading ? <Loading /> : "Skicka"}
            </button>
          </div>
        </>
      );
      break;
  }
}
