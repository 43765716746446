import { useEffect, useState } from "react";
import Loading from "../helpers/Loading";
import trophy from "../../assets/images/trophy.svg";
import bullet from "../../assets/images/bullet.svg";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Subscriptions(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    async function getProd() {
      const config = { headers: { Authorization: "Barer " + props.token } };
      const data = await axios.get(
        process.env.REACT_APP_URL + "/product/subscription",
        config
      );

      if (data.data === "") {
        setData(false);
      } else {
        const proccessed_data = JSON.parse(data.data);
        setData(proccessed_data);
      }

      setLoading(false);
    }
    getProd();
  }, [props.token]);

  return loading ? (
    <Loading />
  ) : (
    <div className="my-subscriptions">
      <h1>Min prenumeration</h1>
      {data ? (
        <article>
          <div className="flex">
            <img src={trophy} alt="trophy" />
            <h2>{data.productName}</h2>
          </div>
          <div className="flex information">
            <p>
              <b>{data.productPrice} </b>
              kr/mån
            </p>
            <img src={bullet} alt="bullet" />
            <p> {data.productInterval}</p>
          </div>
          <p className="text-gray">Löper ut {data.productExpires}</p>
          <button
            onClick={() => {
              const config = {
                headers: { Authorization: "Barer " + props.token },
              };
              axios
                .get(process.env.REACT_APP_URL + "/subscription/cancel", config)
                .then((res) => {
                  setData(false);
                });
            }}
            className="text-gray"
          >
            Avsluta prenumeration
          </button>
        </article>
      ) : (
        <>
          <p>
            Du har ingen pågående prenumeration. Vänligen uppgradera för att få
            tillgång till fler funktioner i Nelale.
          </p>
          <Link to="/checkout">Uppgradera nu</Link>
        </>
      )}
    </div>
  );
}
