import matchIMG from "../../../assets/images/match.svg";
import logo from "../../../assets/logo/logo-black.svg";
import noimage from "../../../assets/images/no-image.png";
import { Link } from "react-router-dom";

export default function MatchPopup(props) {
  return (
    <section className="subscription-popup match">
      <img src={matchIMG} alt="matchIMG" />
      <h1>Det är en matchning!</h1>
      <div className="images">
        <img src={props.data.pic1 ? props.data.pic1 : noimage} alt="user1" />
        <img src={logo} alt="logo" />
        <img src={props.data.pic2 ? props.data.pic2 : noimage} alt="user2" />
      </div>

      <Link
        className="button"
        to={"/chat/user/" + props.data.room}
        onClick={() => {
          props.close();
        }}
      >
        BÖRJA CHATTA
      </Link>

      <p
        className="close"
        onClick={() => {
          props.close();
        }}
      >
        X
      </p>
    </section>
  );
}
