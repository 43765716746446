import Posts from "../Posts";
import LoggedOutHeader from "../helpers/LoggedOutHeader";
import BottomSection from "../BottomSection";

export default function Archive(props) {
  return (
    <>
      {props.loggedout ? <LoggedOutHeader /> : ""}

      <div className="archive">
        <h1>Event och Händelser</h1>
        <Posts token={props.token} hideIntro />
      </div>
      {props.loggedout ? <BottomSection /> : ""}
    </>
  );
}
