import React from "react";
import AuthContextProvider from "./context/authContext";
import { Helmet } from "react-helmet";

import Router from "./Components/Router";
function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Nelale - Sveriges största dejtingplats för Eritreaner och Ethiopie
        </title>
        <meta
          name="description"
          content="Dejtingsidan som ger dig möjligheter att träffa & lära känna din blivande partner"
        />

        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <AuthContextProvider>
        <Router />
      </AuthContextProvider>
    </>
  );
}

export default App;
