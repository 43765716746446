import trophy from "../../assets/images/trophy.svg";

export default function ProductCard(props) {
  return (
    <div
      className={props.active ? "popup-buttom active" : "popup-buttom"}
      onClick={() => {
        props.click();
      }}
    >
      <div className="flex">
        <img src={trophy} alt="trophy" />
        <p>Premium</p>
      </div>
      <p className="text-bold bigger-text">
        {props.price / props.interval}
        <label className="text-bold">kr/mån</label>
      </p>
      <p className="dark-gray">
        {props.interval} {props.interval === 1 ? "månad" : "månader"}
      </p>
      <p className="text-gray">Totalt {props.price} kr </p>
    </div>
  );
}
