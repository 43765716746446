import { useState, useContext } from "react";
import search from "../assets/images/search.svg";
import heart from "../assets/images/heart.svg";
import chat from "../assets/images/chat.svg";
import profile from "../assets/images/profile.svg";
import { PopupContext } from "../context/popupContext";
import englishf from "../assets/images/english.svg";
import swedishf from "../assets/images/swedish.svg";

import Logo from "./helpers/Logo";

import { Link } from "react-router-dom";

export default function MobileHeader(props) {
  const [popup, changePopup] = useContext(PopupContext);
  const [open, setOpen] = useState(false);
  const [english, setEnglish] = useState(false);
  return (
    <header className="hide-small mobileHeader">
      <Link className="logo" to="/">
        <Logo white />
      </Link>

      <button
        id="btn-lang"
        onClick={(e) => {
          changePopup.showPopup(true, "lang");
        }}
      >
        <img src={!english ? englishf : swedishf} alt="english flag" />
      </button>
      <nav>
        <Link to="/search">
          <img alt="search" src={search} />
          <p>Sök</p>
        </Link>
        <Link to="/matches">
          <img alt="mina likes" src={heart} />
          <p>Mina likes</p>
        </Link>
        <Link to="/chat">
          <img alt="meddelanden" src={chat} />
          <p>Meddelanden</p>
        </Link>
        <Link to="/profile">
          <img alt="profil" src={profile} />
          <p>Jag</p>
        </Link>
        {!props.premium ? (
          <Link className="btn-premium hideMiddle" to="/checkout">
            Uppgradera
          </Link>
        ) : (
          ""
        )}
      </nav>
      <div
        className="hamburger"
        onClick={(e) => {
          if (!e.target.classList.contains("hamburger")) {
            e.target.parentElement.classList.toggle("change");
          } else {
            e.target.classList.toggle("change");
          }

          setOpen(!open);
        }}
      >
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <div className={open ? "side-men open" : "side-men"}>
        {!props.loggedout ? (
          <Link to="/subscriptions">Mina prenumerationer</Link>
        ) : (
          ""
        )}
        <Link to="/about-us">Om oss</Link>
        <Link to="/contact">Kontakt</Link>
        <Link to="/faq">Vanliga frågor och svar</Link>
        {!props.loggedout ? (
          <button
            onClick={() => {
              props.logout();
            }}
          >
            Logga ut
          </button>
        ) : (
          ""
        )}
      </div>
    </header>
  );
}
