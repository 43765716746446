import React from "react";
import { Link } from "react-router-dom";
import { home, search, heart, chat, profile } from "../assets/images/icons";

export default function Footer(props) {
  return (
    <footer className="footer hide-large">
      <Link to="/">
        <img src={home} alt="home" />
      </Link>
      <Link to="/search">
        <img src={search} alt="search" />
      </Link>
      <Link to="/matches">
        <img src={heart} alt="likes" />
      </Link>
      <Link to="/chat">
        <img src={chat} alt="chat" />
      </Link>
      <Link to="/profile">
        <img src={profile} alt="profile" />
      </Link>
    </footer>
  );
}
