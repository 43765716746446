import React, { useEffect, useState } from "react";
import MobileHeader from "./MobileHeader";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

export default function Layout(props) {
  const [premium, setPremium] = useState(false);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_URL + "/user/premium", {
        headers: { Authorization: "Barer: " + localStorage.token },
      })
      .then((res) => {
        const data = JSON.parse(res.data);
        setPremium(data.premium);
      });
  }, []);

  return (
    <>
      <Header
        loggedout={props.loggedout}
        logout={props.change}
        premium={premium}
      />
      <MobileHeader
        loggedout={props.loggedout}
        logout={props.change}
        premium={premium}
      />
      {props.children}
      <Footer loggedout={props.loggedout} />
    </>
  );
}
