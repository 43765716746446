import { useEffect } from "react";

export default function LanguagePopup(props) {
  useEffect(() => {
    const googleEl = document.getElementById("google_translate_element");

    if (googleEl && !props.transel) {
      googleEl.style.display = "block";
      document.getElementById("langtar").appendChild(googleEl);
      props.setTransel(googleEl);
    } else {
      document.getElementById("langtar").appendChild(props.transel);
    }
  }, []);
  return (
    <div className="welcome-popup">
      <div className="popup">
        <h1>Ändra språk</h1>
        <div id="langtar"></div>
        <button
          onClick={() => {
            props.close();
          }}
        >
          Stäng
        </button>
      </div>
    </div>
  );
}
