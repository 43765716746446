import React, { useEffect } from "react";
import LoggedOutHeader from "../helpers/LoggedOutHeader";
import BottomSection from "../BottomSection";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loading from "../helpers/Loading";

export default function Contact(props) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [validPhone, setValidPhone] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  function change(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function submitForm(e) {
    e.preventDefault();

    axios
      .post(process.env.REACT_APP_URL + "/data/contact", formData)
      .then((response) => {
        console.log("res", response);
        setLoading(false);
        setMessage("Ditt meddelande har skickats, Vi hör av oss till dig.");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  return (
    <>
      {props.loggedout ? <LoggedOutHeader /> : ""}
      <div className="contact">
        <h1>Hör gärna av dig till oss</h1>
        <div className="the-form">
          <input
            placeholder="NAMN"
            onChange={(e) => {
              change(e);
            }}
            name="name"
          />
          <div className="two-col">
            <input
              placeholder="EPOST"
              onChange={(e) => {
                change(e);
                if (
                  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                    e.target.value
                  )
                ) {
                  setValidEmail(true);
                } else {
                  setValidEmail(false);
                }

                return false;
              }}
              type="email"
              name="email"
            />
            <input
              placeholder="TELEFON"
              onChange={(e) => {
                change(e);
                if (
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                    e.target.value
                  )
                ) {
                  setValidPhone(true);
                } else {
                  setValidPhone(false);
                }
              }}
              name="phone"
            />
          </div>
          <textarea
            placeholder="Meddelande.. "
            name="message"
            onChange={(e) => {
              change(e);
            }}
          />
          <div
            className="acceptance"
            onClick={(e) => {
              setAccepted(e.target.checked);
            }}
          >
            <input type="checkbox" checked={accepted ? true : false} />
            <p>
              Jag godkänner Nelales <Link to="/policy">användarvillkor</Link>
            </p>
          </div>
          <button
            className={
              accepted && validEmail && validPhone
                ? "button"
                : "button disabled"
            }
            onClick={(e) => {
              if (accepted && validPhone && validEmail) {
                setLoading(true);
                submitForm(e);
              }
            }}
          >
            {loading ? <Loading /> : "SKICKA"}
          </button>
          {message ? <p className="success">{message}</p> : ""}
        </div>
      </div>
      <BottomSection />
    </>
  );
}
